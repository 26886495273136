@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 400px
  width: 400px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
  @include modal-dialog
.AdminMfoSend
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  &_Label
    color: $light-grey
    display: flex
    align-items: center
    justify-content: center
    flex: 1 1 auto
    height: 100%
  &_Header
    @include modal-header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    h6
      margin: 0
  &_Content
    height: 600px
    max-height: 600px
    flex: 1 1 auto
    min-height: 0
    padding: 0
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
    &_Search
      padding: 0.5rem 0.5rem
      width: 100%
      position: relative
      display: flex
      align-items: center
      justify-content: space-between
      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px
      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important
    &_Patients
      width: 100%
      overflow: auto
      display: flex
      flex-direction: column
      flex: 1 1 auto
      border-top: 1px solid $light-grey
      border-bottom: 1px solid $light-grey
      &_Patient
        width: 100%
        padding: 0.5rem 0.5rem
        border-bottom: 1px solid $light-grey
        display: flex
        justify-content: space-between
        align-items: center
        &_Profile
          display: flex
          justify-content: flex-start
          align-items: center
          &_Img
            width: 30px !important
            height: 30px !important
            margin-right: 20px
          &_Name
            color: $slate-grey
            font-weight: 500
        &:last-child
          border-bottom: none
        &_CustomCheckbox
          display: flex
          flex-direction: revert
          align-items: center
          justify-content: center
          height: 30px
          width: 30px
          padding: 0
          span
            position: relative !important
  &_CloseIcon
    cursor: pointer
    color: $slate-grey
    font-size: 16px

