@import '../../assets/sass/vars'


.root
  max-width: $layout_max_width
  width: 100%
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: stretch
  position: relative
  &_wrapper
    max-width: 750px
    width: 100%
    margin:  5rem auto
    padding: 2rem
    display: flex
    align-items: stretch
    justify-content: stretch
    border: 1px solid $sky-blue
    background-color: $white
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 30px
    @media only screen and (max-width : 1200px)
      flex: 1 1 auto
      align-items: center
      justify-content: center
      width: 90%
      margin: 0
