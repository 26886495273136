@import '../../assets/sass/vars'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  min-height: 600px !important
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden !important
  @media only screen and (max-width : 480px)
    width: 100% !important
    margin: 0 !important
    padding: 1rem !important
.AppointmentDoctorView
  width: 500px
  @media only screen and (max-width : 480px)
    width: 100%
  height: auto
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 10px 0
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
    overflow: auto
    &_Item
      padding: 10px
      border-bottom: 1px solid $light-grey
      &:last-child
        border-bottom: none
      &_Header
        font-weight: 500 !important
        width: 100%
        display: flex
        margin-bottom: 10px !important
        justify-content: center
        align-items: center
        font-size: 18px !important
        color: $slate-grey !important
    &_Box
      min-height: 40px
      width: 100%
      padding: 10px
      border: 1px solid $light-grey
      background-color: lighten($grey-light, 10%)
      border-radius: 8px
      margin: 5px 0
      color: $slate-grey
      display: flex
      justify-content: flex-start
      align-items: center
    &_Attachments
      width: 100%
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-start
      flex-wrap: wrap
      &_Attachment
        cursor: pointer
        width: 80px
        height: 80px
        margin: 2px
        background-color: $grey-light
        display: flex
        align-items: center
        justify-content: center
        background-position: center center
        background-size: cover
        background-repeat: no-repeat
        &_Icon
          width: 40% !important
          height: 40% !important
          font-size: 32px !important
    &_Patient
      width: 100%
      display: flex
      flex-direction: row
      justify-content: flex-start
      justify-items: center
      &_Avatar
        border-radius: 50%
        width: 60px
        height: 60px
        margin: 5px
        background-size: cover
        background-repeat: no-repeat
        background-position: center center
      &_Profile
        display: flex
        flex: 1 1 auto
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        padding: 5px 10px
        &_Fio
          color: $slate-grey
        &_Age
          color: $grey-light
  &_CloseIcon
    color: $slate-grey
    font-size: 22px
    position: absolute
    cursor: pointer
    right: 1rem

