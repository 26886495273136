@import '../../assets/sass/vars'

.root
  display: flex
  width: 100%
  justify-content: space-between
  margin-top: 1rem
  &_wrapper
    width: 100%
    overflow: hidden
    @media only screen and (max-width : 768px)
      padding: 1rem
