@import '../../../assets/sass/vars'


.loginPhoneConfirmCode
  max-width: 430px
  margin: 0 auto
  &_title
    margin-bottom: 20px
    font-size: 24px
    font-weight: 500
    text-align: center
  &_descr
    margin-bottom: 20px
    font-size: 16px
    line-height: 20px
    font-weight: 400
    text-align: center
  &_msg
    margin-bottom: 40px
    font-size: 16px
    line-height: 20px
    color: $grey-medium
    text-align: center
    &_phone
      color: $sky-blue
  &_form
    text-align: center
  &_code
    max-width: 164px
    margin: 0 auto
    text-align: center
  &_btn
    width: 360px
    @media only screen and (max-width : 576px)
      width: 100%
