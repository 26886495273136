@import '../../assets/sass/vars'
.root
  flex: 1 1 auto
  &_date
    margin: 1rem 0
    color: lighten($sky-purple, 5)
    font-size: $base_font_size
  &_content
    min-height: 400px
    background: $white
    margin: 2rem 0
    padding: 2rem
    border-radius: $border-radius-primary
    border: 1px solid $sky-blue-light
    display: flex
    align-items: stretch
    justify-content: center
