@import '../../assets/sass/vars'

.root
  &_items
    width: 100%
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: stretch
    &_doctors
      width: 100%
      display: flex
      gap: 1rem
      align-items: center
      flex-direction: row
      flex: 1 1 auto
      justify-content: space-between
      flex-wrap: wrap

