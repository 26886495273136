@import '../../assets/sass/vars'
.ReportDropdownMenu
  display: flex
  flex-direction: column
  justify-content: center

  &_DropdownContent
    padding: 0

    &_Calendar
      padding: 5px

    ul
      list-style: none
      margin: 0
      padding: 5px

      li
        min-width: 290px
        min-height: 32px !important

        & > div
          display: flex
          justify-content: flex-start
          align-items: center
          flex: 1 1 auto
          width: 100%
          height: 100%
          margin-right: 10px
        position: relative
        display: flex
        justify-content: space-between
        align-items: center
        padding: 5px 10px
        border-radius: 8px
        cursor: pointer

        &:hover
          background-color: lighten($sky-blue, 50%)

    &_Status
      display: block
      width: 12px
      height: 12px
      border-radius: 50%

      &_CANCELED
        background-color: $yellow-accent

      &_DONE
        background-color: $light-grey

      &_AWAITS
        background-color: $spring-green

      &_FINISHED
        background-color: $steel-blue

      &_IN_PROGRESS
        background-color: $sky-blue

      &_FAILED
        background-color: $red

    &_Icon
      visibility: visible
      width: 22px !important
      height: 22px !important
      margin-left: 20px
      color: $sky-blue
      fill: $sky-blue

      path
        fill: $sky-blue

      &_Hidden
        visibility: hidden

    &_Reset
      padding: 5px
      border-top: 1px solid $grey-light

  &_Buttons
    padding: 0 10px
    display: flex
    justify-content: space-between
    align-items: stretch

    button
      height: 100%
      position: relative

      &:first-child
        margin-right: 5px

    &_Status
      border-radius: 50%
      background-color: $red
      top: -2px
      right: -2px
      position: absolute
      width: 10px
      height: 10px
