@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: flex-start
  margin-bottom: 2rem
  &_filters
    display: flex
    background-color: $white
    align-items: stretch
    border-radius: $border-radius-primary
    border: 2px solid $sky-blue-light
    overflow: hidden
    justify-content: space-between
    margin-right: 2rem
    &_reset
      color: $sky-blue
      margin-left: 2rem
      cursor: pointer
  &_filter
    color: $sky-blue
    background-color: transparent
    font-weight: 500
    font-size: 12px
    min-width: 50px
    display: flex
    justify-content: center
    cursor: pointer
    padding: 0.2rem 1rem
    line-height: 30px
    border-right: 2px solid $sky-blue-light
    &:last-child
      border-right: none
    &_date
      align-items: center
      background-color: $sky-blue-light2
      border-radius: $border-radius-primary
      border: 2px solid $sky-blue-light
      svg
        color: $sky-blue
        path
          stroke: $sky-blue
      & > div:last-child
        text-align: center
        width: 120px !important
        flex: 1 1 auto
      & > div:first-child
        display: none
      &_active
        position: relative
        background: linear-gradient(180deg, #0D64E2 0%, #2197E7 100%)
        color: $white
        svg
          color: $white
          path
            stroke: $white
        & > div:first-child
          display: block
          width: 10px
          height: 10px
          border-radius: 50%
          position: absolute
          top: -2px
          right: -2px
          background-color: $red
    &_active
      background-color: $sky-blue-light2
      color: $sky-blue
