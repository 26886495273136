@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: space-between
  color: $sky-purple
  font-weight: 500
  margin: 1rem 0 2rem 0

