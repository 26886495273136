@import '../../assets/sass/vars'


.root
  display: flex
  width: 100%
  height: 80px
  background-color: $white
  z-index: 999
  margin-bottom: 2rem
  position: sticky
  justify-content: space-between
  @media only screen and (max-width : 992px)
    padding: 0 1rem
    margin-bottom: 0
  &_apps
    display: flex
    flex: 1 1 auto
    align-items: center
    justify-content: flex-end
    @media only screen and (max-width : 992px)
      display: none
    &_email
      border-radius: $border-radius-primary
      width: 200px
      height: 40px
      align-items: center
      justify-content: center
      display: flex
      color: $sky-blue
      span
        border-bottom: 2px dotted $sky-blue
    &_item
      display: block
      width: 134px
      height: 40px
      svg
        width: 100%

.headerAuthMenuList
  display: inline-flex
  &_item
    padding: 0 18px
    font-size: 16px
    line-height: 20px
    color: $slate-grey
    text-decoration: none
    +mt(.2s)
    &:not(:last-child)
      border-right: 1px solid $grey-light
    &_active, &:hover
      color: $sky-blue

@media only screen and (max-width : 992px)
  .appButtonsList
    display: none
