@import '../../assets/sass/vars'


.root
  font-size: 22px
  font-weight: 600
  line-height: 22px
  color: $royal-blue
  &_center
    text-align: center
