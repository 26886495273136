@import '../../assets/sass/vars'

.root
  padding: 1rem 0.5rem
  background: $sky-blue-light-second
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  display: flex
  flex-direction: column
  justify-content: stretch
  &_list
    display: flex
    flex-direction: column
    align-items: stretch
    gap: 0.5rem
    &_item
      display: flex
      position: relative
      justify-content: flex-start
      align-items: center
      padding: 6px 13px
      font-size: 18px
      line-height: 22px
      cursor: pointer
      color: $sky-purple
      font-weight: 500
      border-right: 4px transparent solid
      &_active
        border-right-color: $sky-blue
      &:hover, &_active
        color: $sky-blue
        .root_list_item_icon
          fill: $sky-blue
          path
            fill: $sky-blue
        .root_list_item_icon2
          path
            stroke: $sky-blue
        .root_list_item_icon3
          path
            stroke: $sky-blue
      &_icon
        width: 18px
        height: 18px
        margin-right: 1rem
        fill: $sky-purple
        path
          fill: $sky-purple
      &_icon2
        width: 18px
        height: 18px
        margin-right: 1rem
        path
          stroke: $sky-purple
      &_icon3
        width: 18px
        height: 18px
        margin-right: 1rem
        path
          stroke: $sky-purple
