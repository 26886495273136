@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: space-between
  background-color: $sky-blue-light2
  border-radius: $border-radius-primary
  border: 1px solid $sky-blue-light
  &_item
    width: 20%
    color: $royal-blue
    display: flex
    align-items: center
    font-weight: 500
    padding: 1rem

