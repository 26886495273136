@import '../../assets/sass/vars'
.SearchDoctors
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  input
    background-color: $grey-light
    width: 100%
    padding: 12px 40px
  &_Icon
    position: absolute
    left: 0
    top: 0
    width: 22px !important
    height: 22px !important
    margin: 10px
    &_Clear
      cursor: pointer
      left: auto !important
      right: 0 !important
  &_Popover
    z-index: 10000
    width: 100%
    max-height: 300px
    position: absolute
    display: flex
    justify-content: flex-start
    align-items: flex-start
    top: 45px
    left: 0
    background-color: $white
    border-radius: 8px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25)
    overflow: auto
    &_List
      width: 100%
      height: 100%
      display: flex
      flex: 1 1 auto
      overflow: auto
      flex-direction: column
      justify-content: stretch
      align-items: center
      li
        cursor: pointer

