@import '../../../../assets/sass/vars'
.ReportsPageAdminServicePackageTransactionItems
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  flex: 1 1 auto
  height: 100%
  width: 100%
  &_InfiniteScroll
    width: 100%
    display: flex
    align-items: center
    justify-content: center
  &_Toolbar
    display: flex
    flex-direction: column
    justify-content: center
    &_Search
      position: relative
      padding: 10px 0
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      margin-right: 20px
      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px
      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important
    &_Buttons
      padding: 5px 25px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
    &_Types
      padding: 5px 25px
      display: flex
      justify-content: space-between
      align-items: stretch
      border-bottom: 1px solid $grey-light
    &_Filters
      padding: 5px 25px
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      &_Groups
        padding: 5px 25px
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        border-bottom: 1px solid $grey-light
      &_Buttons
        padding: 5px 25px
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        border-bottom: 1px solid $grey-light
    &_Divider
      width: 1px
      background-color: $grey-light
      height: 100%
      line-height: 40px
      margin: 0 20px
    &_Search
      display: flex
      flex: 1 1 auto
      width: 70%
      position: relative
    &_DropdownContent
      padding: 0
      &_Calendar
        padding: 5px
      ul
        list-style: none
        margin: 0
        padding: 5px
        li
          min-width: 290px
          min-height: 32px !important
          & > div
            display: flex
            justify-content: flex-start
            align-items: center
            flex: 1 1 auto
            width: 100%
            height: 100%
            margin-right: 10px
          position: relative
          display: flex
          justify-content: space-between
          align-items: center
          padding: 5px 10px
          border-radius: 8px
          cursor: pointer
          &:hover
            background-color: lighten($sky-blue, 50%)
      &_Status
        display: block
        width: 12px
        height: 12px
        border-radius: 50%
        &_PURCHASED
          background-color: $yellow-accent
        &_NEW
          background-color: $light-grey
        &_ACTIVATED
          background-color: $spring-green
        &_SENT
          background-color: $steel-blue
        &_CLOSED
          background-color: $red
        &_CANCELED
          background-color: #f56642
      &_Icon
        visibility: visible
        width: 22px !important
        height: 22px !important
        margin-left: 20px
        color: $sky-blue
        fill: $sky-blue
        path
          fill: $sky-blue
        &_Hidden
          visibility: hidden
      &_Reset
        padding: 5px
        border-top: 1px solid $grey-light
    &_Buttons
      padding: 0 10px
      display: flex
      justify-content: space-between
      align-items: stretch
      button
        height: 100%
        position: relative
        &:first-child
          margin-right: 5px
      &_Status
        border-radius: 50%
        background-color: $red
        top: -2px
        right: -2px
        position: absolute
        width: 10px
        height: 10px
  &_Content
    display: flex
    flex: 1 1 auto
    height: 100%
    flex-direction: column
    align-items: center
    justify-content: center
  &_Section
    width: 100%
    border-bottom: 1px solid $grey-light
    &:last-child
      border-bottom: none
  &_Items
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    height: 100%
    width: 100%
    flex: 1 1 auto
    @media (max-width: 480px)
      padding: 10px
    padding: 10px
    &_Item
      border: 1px solid $grey-light
      width: 100%
      display: flex
      border-radius: 8px
      flex-direction: column
      justify-content: space-between
      align-items: stretch
      padding: 5px 10px
      margin-bottom: 10px
      &_Name
        color: $slate-grey
        font-weight: 500
        margin-bottom: 5px
        display: flex
        flex-direction: row
      &_Tariff
        color: $slate-grey
        margin-bottom: 5px
        a
          color: $sky-blue
      &_Info
        color: $light-grey
        margin-top: 10px
        margin-bottom: 5px
      &_Label
        color: $slate-grey
        margin-bottom: 5px
        display: flex
        flex-direction: row
        font-weight: 500
      &_Status
        display: inline-block
        &_PURCHASED
          color: $yellow-accent
        &_NEW
          color: $light-grey
        &_ACTIVATED
          color: $spring-green
        &_SENT
          color: $steel-blue
        &_CLOSED
          color: $red
        &_CANCELED
          color: #f56642



