@import '../../../../../assets/sass/vars'


.doc
  display: inline-block
  padding: 1rem 0 1rem 3rem
  background-image: url('../../../../../assets/img/icons/pdf-icon.svg')
  background-repeat: no-repeat
  background-size: 21px
  background-position: 10px center
  +mt(.2s)
  &:hover
    color: $sky-blue

.image
  height: 120px
  padding: 15px
  background-size: cover
  background-repeat: no-repeat
  background-position: top center
  border-radius: 12px
  border: 1px solid $sky-blue
  font-style: italic
  cursor: pointer
  font-size: 14px
  line-height: 17px
  text-align: center
