@import '../../../../../../../assets/sass/vars'
@import '../../../../../../../assets/sass/mixin'
.root
  padding: 1rem
  width: 100%
  display: flex
  flex-direction: column
  background: $white
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  height: 100%
  justify-content: space-between
  &_desc
    display: flex
    gap: 1rem
    margin-bottom: 1rem
    width: 100%
    align-items: flex-start
    justify-content: space-between
  &_preview
    border-radius: $border-radius-primary
    overflow: hidden
    width: 80px
    height: 80px
    cursor: pointer
    &_video
      height: 120px
      background-color: $sky-blue-light
    &_play
      display: flex
      align-items: center
      justify-content: center
      font-size: 40px
      color: $white
      cursor: pointer
      svg
        font-size: 40px
        width: 40px
        height: 40px
    background-repeat: no-repeat
    background-size: cover
    background-position: center top
    img
      visibility: hidden
      height: 100%
      width: 100%
  &_text
    flex: 1 1 auto
    width: calc(100% - 60px)
    display: flex
    flex-direction: column
    gap: 1rem
    &_title
      font-weight: 500
      color: $royal-blue
    &_sub
      font-size: $base_font_size - 1px
      color: $royal-blue
  button
    position: relative
    input[type=file]
      cursor: pointer
      display: block
      height: 100%
      position: absolute
      width: 100%
      opacity: 0

