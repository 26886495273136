@import '../../assets/sass/vars'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  background-color: transparent !important
  box-shadow: none !important
.SchedulerDelete
  width: 386px
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    h6
      margin: 0
  &_Content
    height: auto
    min-height: 0
    padding: 0
  &_CloseIcon
    color: $slate-grey
    font-size: 16px

