@import '../../assets/sass/vars'

.PatientsDoctor
  &_Conclusion
    color: $slate-grey
    display: flex
    justify-content: flex-start
    align-items: center
    &_Icon
      color: $sky-blue
      width: 22px !important
      height: 22px !important
      margin-right: 10px
