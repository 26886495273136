@import '../../assets/sass/vars'


.rawPageLayoutWrapper
  position: relative
  padding: 0
  width: 100%
  height: auto
  min-height: 100%
  overflow-x: hidden
  background-color: transparent
