@import '../../assets/sass/vars'

.CallToolbar
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  position: relative
  &_IconButton
    padding: 6px !important
    position: relative
    background: linear-gradient(90deg, #166AE3 0%, #2197E8 100%)
  &_Status
    width: 10px
    height: 10px
    background-color: $red
    top: 0
    right: 0
    position: absolute
    border-radius: 50%
  &_Icon
    cursor: pointer
    color: $white
