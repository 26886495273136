@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 800px !important
  width: 100% !important
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
  @media only screen and (max-width : 790px)
    padding: 0 !important
    max-width: 95% !important
.AdminDoctorView
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  @media only screen and (max-width : 790px)
    margin: 0 !important
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    display: flex
    flex: 1 1 auto
    padding: 10px 0
    align-items: center
    flex-direction: column
    justify-content: center
    overflow: hidden
    form
      display: flex
      flex-direction: column
      flex: 1 1 auto
      justify-content: center
      align-items: stretch
      width: 100%
      overflow: auto
    &_Wrapper
      display: flex
      flex-direction: column
      width: 100%
      max-height: 100%
      flex: 1 1 auto
      overflow: auto
    &_Item
      padding: 10px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      &_Save
        padding-left: 60px
        padding-right: 60px
      &_Container
        align-items: stretch !important
        padding: 1rem
      &:last-child
        border-bottom: none
      &_ProfileImg
        width: 120px !important
        height: 120px !important
        border: 1px solid $sky-blue
        margin-bottom: 10px
        &_Add
          color: $sky-blue
          cursor: pointer
          position: relative
          input[type=file]
            opacity: 0
            position: absolute
            margin: 0
            top: 0
            left: 0
            width: 100%
            height: 100%
            cursor: pointer
      &_Docs
        width: 100%
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: flex-start
        flex-wrap: wrap
        &_Box
          display: flex
          margin: 5px
          flex-direction: column
          justify-content: stretch
          align-items: flex-start
          width: 160px
          height: 200px
          &_Item
            cursor: pointer
            width: 100%
            background-position: center center
            background-repeat: no-repeat
            background-size: cover
            display: flex
            flex: 1 1 auto
          &_Action
            width: 100%
            color: $sky-blue
            cursor: pointer
            display: flex
            margin: 10px 0
            justify-content: center
            align-items: center
        &_Add
          position: relative
          &_Icon
            width: 44px !important
            height: 44px !important
            color: $sky-blue
            cursor: pointer
            position: relative
          input[type=file]
            opacity: 0
            position: absolute
            margin: 0
            top: 0
            left: 0
            width: 100%
            height: 100%
            cursor: pointer
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close
  &_CheckBox
    font-size: 14px
    margin-bottom: 6px
