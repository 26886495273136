@import '../../assets/sass/vars'


.Footer
  bottom: 0
  position: fixed
  width: 100%
  background-color: transparent
  padding: 20px 50px
  display: flex
  align-items: center
  justify-content: center
  margin: 0
  @media only screen and (max-width : 480px)
    padding: 20px 20px
  p
    margin: 0
    color: $slate-grey
.root
  height: 100%
  display: flex
  align-items: stretch
  justify-content: center
  &_wrapper
    flex: 1 1 auto
    width: 100%
    display: flex
    flex-direction: column
    max-width: $layout_max_width
    align-items: center
    justify-content: flex-start

