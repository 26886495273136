@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
// Bourbon mixins
@import "bourbon"

// Css libs
@import "libs"

// Fonts
@import "fonts/fonts"

// Sass variables
@import "vars"

// Display managing
@import "display"

// Flex classes
@import "flex"

// Spacing
@import "spacing"

// Floating
@import "float"

// Text aligning
@import "text"


*, *::before, *::after
  box-sizing: border-box

*::-webkit-input-placeholder
  font-family: $base_font
  font-weight: 300
  color: $light-grey
  font-size: 16px
  opacity: 1

html
  font-family: $base_font !important
  height: 100%

body
  position: relative
  min-width: 320px
  height: 100%
  margin: 0
  padding: 0
  font-family: $base_font !important
  color: $slate-grey
  background-color: $white
  font-size: 16px
  line-height: 20px
  font-weight: 400
  overflow-y: scroll !important
  background-image: url("../img/bg.png")
  background-repeat: no-repeat
  background-size: 120% auto
  background-position: center center
  @media only screen and (min-width: 1400px)
    background-image: none
input, select
  font-family: $base_font !important
#root
  height: 100%

section
  padding: 15px 0
  .sect-title
    margin-bottom: 20px
    text-align: center


h1, h2, h3, h4, h5, h6
  margin: 10px 0
  color: #000
  font-weight: 400
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

h1
  font-size: 70px
  line-height: 80px
  font-weight: 500
h2
  font-size: 58px
  line-height: 70px
  font-weight: 500
h3
  font-size: 24px // !!
  line-height: 28px
  font-weight: 500
h4
  font-size: 20px
  line-height: 24px
  font-weight: 500
h5
  font-size: 18px
  line-height: 22px
  font-weight: 400
h6
  font-size: 20px
  line-height: 24px
  font-weight: 400

p
  margin: 20px 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

a
  color: $slate-grey
  text-decoration: none
  &:hover
    text-decoration: none

hr
  height: 1px
  border: 0
  background-color: darken($ghost-white, 5%)

img
  &.img-fluid
    display: inline-block
    max-width: 100%

b
  font-weight: 700


.card-bg
  padding: 20px
  border: 1px solid $sky-blue
  background-color: #fff
  box-shadow: 0px 3px 20px rgba(83, 138, 218, 0.35)
  border-radius: 30px


// Search bar input
.search-bar-wrapper
  position: relative
  .search-bar-icon
    position: absolute
    top: 11px
    left: 16px
    color: lighten($light-grey, 10%)
  .search-bar
    display: inline-block
    width: 100%
    padding: 8px 16px
    padding-left: 37px
    border: 1px solid $ghost-white
    border-radius: 20px
    color: $light-grey

fieldset
  border: none


// File input
.input-file-container
  display: inline-block
  text-align: center
  .input-file-trigger
    cursor: pointer
  .input-file
    display: none
  .input-file-return
    margin: 0
    margin-top: 10px


// List group
ul.list-group
  margin: 0
  padding: 0
  list-style-type: none
  li
    &:not(:last-child)
      margin-bottom: 10px


.details-list
  margin: 0
  padding: 0
  list-style-type: none
  .details-item
    display: block
    &:not(:last-child)
      margin-bottom: 16px
    dl
      display: table
      width: 100%
      margin: 0
      dt
        display: table-cell
        width: 45%
        font-weight: 400
        padding-right: 10px
        vertical-align: top
        font-size: 14px
        line-height: 16px
      dd
        display: table-cell
        padding-right: 10px
        vertical-align: top
        color: $grey-medium
        font-size: 14px
        line-height: 16px


// Bullets list
.bullets-list
  margin: 0
  padding: 0
  list-style-type: none
  .bullet-item
    display: block
    position: relative
    padding-left: 40px
    font-size: 15px
    &:not(:last-child)
      margin-bottom: 15px
    &::before
      position: absolute
      top: 0
      left: 0
      content: '\f00c'
      font-family: 'FontAwesome'
      color: $brown
      font-size: 20px


.raw-list
  margin: 0
  padding: 0
  list-style-type: none


// Socials
.socials-list
  display: inline-flex
  align-items: center
  margin: 0
  padding: 0
  list-style-type: none
  li
    display: inline-block
    margin: 10px

.social-link
  position: relative
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  text-align: center
  cursor: pointer
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #000
    opacity: 0
    -webkit-transition: all .2s ease
            transition: all .2s ease
  &:hover
    &::before
      opacity: .1
  i
    position: relative
    color: $slate-grey
  &.micro
    width: 25px
    height: 25px
    border-radius: 3px
    i
      font-size: 14px
  &.small
    width: 32px
    height: 32px
    border-radius: 4px
    i
      font-size: 25px
  &.large
    width: 40px
    height: 40px
    border-radius: 4px
    i
      font-size: 22px

a.social-link
  text-decoration: none


// React Toastify
.Toastify__toast-container--top-right
  top: 80px

.Toastify__toast--success
  background: $spring-green
  .Toastify__progress-bar
    background-color: #06C802

.Toastify__toast--error
  background: lighten(#E74C3C, 7%)
  .Toastify__progress-bar
    background-color: darken(#E74C3C, 5%)

.Toastify__toast
  padding: 12px 23px 17px
  border-radius: 8px
  font-size: 14px
  min-height: auto
  line-height: 16px
  font-family: $base_font

.Toastify__toast-body
  margin-right: 20px

.Toastify__close-button
  margin-top: 4px
  font-size: 15px
  color: transparent
  width: 9px
  height: 9px
  background-image: url('../img/icons/cross-icon-white.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center center
  fill: #fff


.toggle-checkbox
  position: relative
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  .label
    margin-bottom: 0
    margin-right: 20px
input[type=checkbox]
  &:disabled + .checkmark
    cursor: not-allowed
.toggle-checkbox-label
  display: inline-flex
  align-items: center
  justify-content: space-between
  .checkmark
    position: relative
    display: inline-block
    width: 52px
    height: 32px
    background-color: $silver
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.15)
    border-radius: 27px
    cursor: pointer
    flex-shrink: 0
    .check
      position: absolute
      top: 1.1px
      left: 2px
      display: inline-block
      width: 29px
      height: 29px
      background-color: #fff
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15)
      border-radius: 100%
      +mt(.2s)
  input
    position: absolute
    opacity: 0
    &:checked ~ .checkmark .check
      left: 21px
    &:checked ~ .checkmark
      background-color: $sky-blue


// Tooltips
.tippy-box
  background: #FFFFFF
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.14)
  border-radius: 12px
  color: $grey-medium
  font-family: $base_font
  font-weight: 400
  font-size: 12px
  line-height: 16px
  .tippy-content
    padding: 15px

.tippy-box[data-placement^=top] > .tippy-arrow:before
  border-top-color: #fff

.tippy-box[data-placement^=right] > .tippy-arrow:before
  border-right-color: #fff

.tippy-box[data-placement^=bottom] > .tippy-arrow:before
  border-bottom-color: #fff

.tippy-box[data-placement^=left] > .tippy-arrow:before
  border-left-color: #fff


// To top
.to-top
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  bottom: 30px
  right: 30px
  width: 48px
  height: 48px
  border-radius: 5px
  color: #fff
  background-color: $brown
  z-index: 995
  cursor: pointer
  transform: scale(0)
  opacity: .9
  +mt(.2s)
  i
    font-size: 45px
    color: #fff
    &.fa-angle-up
      margin-bottom: 7px
  &.active
    transform: scale(1)
  &:hover
    opacity: 1
    i
      color: #fff


// Buttons
a.bttn
  cursor: pointer

button
  outline: none !important

a
  cursor: pointer

.link-disabled
  pointer-events: none

.navlink
  text-decoration: none

.link-raw
  color: inherit
  text-decoration: none
  cursor: pointer
  &:hover
    color: inherit

// Links
.link
  display: inline-block
  padding-bottom: 5px
  position: relative
  color: $sky-blue
  font-size: 16px
  line-height: 12px
  text-decoration: none
  border-bottom: 1px solid transparent
  +mt(.1s)
  &:not(:disabled)
    cursor: pointer
  &:not(.link-underline)
    border-bottom-color: transparent
  &:hover
    &:not(:disabled)
      border-bottom-color: $sky-blue

.link-dashed
  border-bottom-style: dashed

.link-dotted
  border-bottom-style: dotted

.link-orange
  color: $orange-accent
  border-bottom-color: $orange-accent
  &:hover
    border-bottom-color: $orange-accent

.link-red
  color: $red
  border-bottom-color: $red
  &:hover
    border-bottom-color: $red
    color: $red

.link-green
  color: $spring-green
  border-bottom-color: $spring-green
  &:hover
    border-bottom-color: $spring-green
    color: $spring-green

.link-yellow
  color: $yellow-accent
  border-bottom-color: $yellow-accent
  &:hover
    border-bottom-color: $yellow-accent
    color: $yellow-accent




// App Text colors
.text-orange-accent
  color: $orange-accent
.text-orange-bg
  color: $orange-bg
.text-black
  color: $black
.text-white
  color: $white
.text-grey-bg
  color: $grey-bg
.text-grey-light
  color: $grey-light
.text-grey-medium
  color: $grey-medium
.text-red
  color: $red
.text-yellow-accent
  color: $yellow-accent


// Text
.text-white
  color: #fff
.text-beige
  color: $beige
.text-honey-dew
  color: $honey-dew
.text-steel-blue
  color: $steel-blue
.text-sky-blue
  color: $sky-blue
.text-royal-blue
  color: $royal-blue
.text-sky-blue
  color: $sky-blue
.text-lime-green
  color: $lime-green
.text-orange
  color: $orange
.text-flush-orange
  color: $flush-orange
.text-spring-green
  color: $spring-green
.text-gold
  color: $gold
.text-steel-blue
  color: $steel-blue
.text-sky-blue
  color: $sky-blue
.text-luminous-red
  color: $luminous-red
.text-indiang-red
  color: $indiang-red
.text-dim-grey
  color: $dim-grey
.text-slate-grey
  color: $slate-grey
.text-silver
  color: $silver
.text-light-grey
  color: $light-grey
.text-ghost-white
  color: $ghost-white
.text-white-smoke
  color: $white-smoke

.text-highlight
  color: $gold

.text-uppercase
  text-transform: uppercase


// Fonts
.fw-hairlight
  font-weight: 100 !important
.fw-light
  font-weight: 300 !important
.fw-regular
  font-weight: 400 !important
.fw-bold
  font-weight: 700 !important
.fw-black-bold
  font-weight: 900 !important


// App Bacgorunds
.orange-accent-bg
  background-color: $orange-accent
.orange-bg-bg
  background-color: $orange-bg
.black-bg
  background-color: $black
.white-bg
  background-color: $white
.grey-bg-bg
  background-color: $grey-bg
.grey-light-bg
  background-color: $grey-light
.grey-medium-bg
  background-color: $grey-medium
.red-bg
  background-color: $red
.yellow-accent-bg
  background-color: $yellow-accent


// Backgrounds
.beige-bg
  background-color: $beige
  &.border
    border-color: darken($beige, 10%)
.honey-dew-bg
  background-color: $honey-dew
  &.border
    border-color: darken($honey-dew, 10%)
.lime-green-bg
  background-color: $lime-green
  &.border
    border-color: darken($lime-green, 10%)
.orange-bg
  color: #fff
  background-color: $orange
  &.border
    border-color: darken($orange, 10%)
.flush-orange-bg
  color: #fff
  background-color: $flush-orange
  &.border
    border-color: darken($flush-orange, 10%)
.spring-green-bg
  background-color: $spring-green
  &.border
    border-color: darken($spring-green, 10%)
.gold-bg
  background-color: $gold
  &.border
    border-color: darken($gold, 10%)

.steel-blue-bg
  color: #fff
  background-color: $steel-blue
  &.border
    border-color: darken($steel-blue, 10%)
.sky-blue-bg
  color: #fff
  background-color: $sky-blue
  &.border
    border-color: darken($sky-blue, 10%)
.royal-blue-bg
  color: #fff
  background-color: $royal-blue
  &.border
    border-color: darken($royal-blue, 10%)

.luminous-red-bg
  background-color: $luminous-red
  &.bordered
    border-color: darken($luminous-red, 10%)
.indiang-red-bg
  background-color: $indiang-red
  &.bordered
    border-color: darken($indiang-red, 10%)

.dim-grey-bg
  background-color: $dim-grey
  &.border
    border-color: darken($dim-grey, 10%)
.slate-grey-bg
  background-color: $slate-grey
  &.border
    border-color: darken($slate-grey, 10%)
.silver-bg
  background-color: $silver
  &.border
    border-color: darken($silver, 10%)
.light-grey-bg
  background-color: $light-grey
  &.border
    border-color: darken($light-grey, 10%)
.ghost-white-bg
  background-color: $ghost-white
  &.border
    border-color: darken($ghost-white, 10%)
.white-smoke-bg
  background-color: $white-smoke
  &.border
    border-color: darken($white-smoke, 10%)

.blue-gradient-bg
  background: linear-gradient(to right, $steel-blue, $sky-blue)

.fb-bg
  background-color: $fb-color
.tw-bg
  background-color: $tw-color
.vk-bg
  background-color: $vk-color
.gp-bg
  background-color: $gp-color
.ok-bg
  background-color: $ok-color


// Border
.border, .bttn.border
  border: 1px solid $silver
.border-dashed, .bttn.border-dashed
  border: 1px dashed $silver
.border-dotted, .bttn.border-dotted
  border: 1px dotted $silver


// Rounded
.rounded
  border-radius: 5px
.rounded-circle
  border-radius: 100%

.field-right-offset
  padding-right: 26px

.ReactModal__Body--open,
.ReactModal__Html--open
  position: fixed
  width: 100%
  overflow-y: scroll
.MuiDialog-paper
  padding: 2rem
.Modal
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  background: $white
  overflow: hidden
  border: 1px solid $sky-blue-light
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  &-close
    color: $sky-blue
    font-size: 22px
    position: absolute
    cursor: pointer
    right: 1rem
  &-header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    background: $sky-blue-light-second
    border-bottom: 1px solid $sky-blue-light
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
    padding: 1rem 2rem
    .page-header-title
      margin: 0

