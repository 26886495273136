@import '../../../../../assets/sass/vars'
.root
  display: flex
  width: 100%
  background: $white
  border-bottom: 1px solid $sky-blue-light
  justify-content: space-between
  cursor: pointer
  &:last-child
    border-bottom: none
  &_cell
    font-size: $base_font_size
    color: $royal-blue
    display: flex
    align-content: flex-start
    align-items: center
    padding: 0.5rem 2rem
    word-break: break-all
  &_name
    font-weight: 500
  &_drag
    cursor: pointer
    color: $sky-blue
  &_delete
    cursor: pointer
    color: $sky-blue
  &_content
    width: 50px
    height: 50px
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    border-radius: 50%
    img
      visibility: hidden
