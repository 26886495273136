@import '../../../assets/sass/vars'

.inputFileContainerDragndrop
  display: flex
  justify-content: center
  align-items: center
  padding: 20px
  min-height: 170px
  background-color: $grey-bg
  cursor: pointer
  border-radius: 8px
  border: 1px dashed $grey-medium
  outline: none
  transition: background-color .2s ease, border-color .2s ease, color .2s ease
  &:hover, &_drag_over
    &:not(.inputFileContainerDragndrop_has_files):not(.inputFileContainerDragndrop_is_disabled)
      background-color: darken($grey-bg, 5%)
      border-color: $sky-blue
      color: $sky-blue
  &_has_files
    padding: 10px 20px
    border-color: transparent !important
    cursor: default
    background-color: #fff
  &_is_disabled
    cursor: not-allowed


.inputFile
  display: none

.returnFilesList
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px))
  grid-gap: 20px
  width: 100%

.fileToUpload
  position: relative
  display: flex
  cursor: pointer
  height: 120px
  padding: 10px
  padding-bottom: 52px
  border-radius: 2px
  background-color: #F2F2F7
  & > div
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  & > .fileIcon
    text-align: left
    width: 100%
  .fileRemoveIcon
    position: absolute
    bottom: 10px
    right: 10px
    display: inline-flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 100%
    background-color: #fff
    cursor: pointer
    &_icon
      width: 15px
      fill: $sky-blue
      path
        fill: $sky-blue


.fileName
  font-size: 14px
  line-height: 16px
  color: #0047B1
  text-align: left
  width: 70%
  padding-left: 12px
  margin: 0

.spinner
  background: rgba(255, 255, 255, 0.3)
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  align-items: center
  justify-content: center

.inputFileContainerDragndrop
  position: relative
