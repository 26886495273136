@import '../../../assets/sass/vars'
.DoctorsPageAdmin
  &_InfiniteScroll
    width: 100%
  &_Profile
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    &_Name
      color: $slate-grey
      font-weight: 500
      margin-bottom: 10px
    &_Item
      color: $slate-grey
    &_Status
      &_DropdownContent
        padding: 0
        position: absolute
        left: 0
        width: 350px
        ul
          display: flex
          flex-direction: column
          list-style: none
          margin: 0
          padding: 5px
          li
            display: flex
            min-height: 32px !important
            & > div
              display: flex
              justify-content: flex-start
              align-items: center
              flex: 1 1 auto
              width: 100%
              height: 100%
              margin-right: 10px
              & > div
                white-space: nowrap
            position: relative
            justify-content: space-between
            align-items: center
            padding: 5px 10px
            border-radius: 8px
            cursor: pointer
            &:hover
              background-color: lighten($sky-blue, 50%)
        &_Status
          display: block
          width: 12px
          height: 12px
          border-radius: 50%
          &_BUSY
            background-color: $yellow-accent
          &_AWAY
            background-color: $light-grey
          &_DUTY
            background-color: $spring-green
          &_INVITED
            background-color: $steel-blue
          &_NOT_DUTY
            background-color: $red
        &_Icon
          visibility: visible
          width: 22px !important
          height: 22px !important
          margin-left: 20px
          color: $sky-blue
          fill: $sky-blue
          path
            fill: $sky-blue
          &_Hidden
            visibility: hidden
      &_BUSY
        color: $yellow-accent
      &_AWAY
        color: $light-grey
      &_DUTY
        color: $spring-green
      &_INVITED
        color: $steel-blue
      &_NOT_DUTY
        color: $red
  &_ProfileImg
    border: 1px solid $grey-light
    margin-right: 20px
    width: 60px !important
    height: 60px !important
  &_Row
    border-bottom: 1px solid $light-grey
    padding: 10px 0
    width: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    align-items: flex-start
    &_Main
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: flex-start
    &:last-child
      border-bottom: 1px solid transparent
    &_Cell
      padding: 0 5px
      display: flex
      justify-content: flex-end
      align-items: flex-start
      width: 50%
      &:first-child
        justify-content: flex-start
  &_Button
    color: $slate-grey
    margin-bottom: 10px
  &_Toolbar
    display: flex
    justify-content: stretch
    align-items: center
    padding: 10px 0
    border-bottom: 1px solid $grey-light
    &_DropdownContent
      padding: 0
      ul
        list-style: none
        margin: 0
        padding: 5px
        li
          min-width: 290px
          min-height: 32px !important
          & > div
            display: flex
            justify-content: flex-start
            align-items: center
            flex: 1 1 auto
            width: 100%
            height: 100%
            margin-right: 10px
          position: relative
          display: flex
          justify-content: space-between
          align-items: center
          padding: 5px 10px
          border-radius: 8px
          cursor: pointer
          &:hover
            background-color: lighten($sky-blue, 50%)
      &_Status
        display: block
        width: 12px
        height: 12px
        border-radius: 50%
        &_BUSY
          background-color: $yellow-accent
        &_AWAY
          background-color: $light-grey
        &_DUTY
          background-color: $spring-green
        &_INVITED
          background-color: $steel-blue
        &_NOT_DUTY
          background-color: $red
      &_Icon
        visibility: visible
        width: 22px !important
        height: 22px !important
        margin-left: 20px
        color: $sky-blue
        fill: $sky-blue
        path
          fill: $sky-blue
        &_Hidden
          visibility: hidden
      &_Reset
        padding: 5px
        border-top: 1px solid $grey-light
    &_Buttons
      padding: 0 10px
      display: flex
      justify-content: space-between
      align-items: stretch
      button
        height: 100%
        position: relative
        &:first-child
          margin-right: 5px
      &_Status
        border-radius: 50%
        background-color: $red
        top: -2px
        right: -2px
        position: absolute
        width: 10px
        height: 10px
    &_Search
      position: relative
      padding: 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px
      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important


