@import '../../../assets/sass/vars'
.root
  display: flex
  overflow: auto
  padding: 0.5rem 0.2rem
  &_item
    margin-right: 1rem
    &:last-child
      margin-right: 0
    cursor: pointer
    min-width: 145px
    width: 100%
    max-height: 100%
    height: 139px
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    border-radius: $border-radius-primary
    border: 1px solid $sky-blue-light
    position: relative
    overflow: hidden
    background-repeat: no-repeat
    background-position: right bottom
    background-color: $sky-blue-light2
    &_active
      background-color: $sky-blue-light
    &_title
      white-space: nowrap
      text-overflow: ellipsis
      width: 100%
      padding: 1rem
      font-weight: 500
      font-size: $base_font_size + 1
      color: $royal-blue
