@import '../../../assets/sass/vars'


.loginScreen
  max-width: 360px
  margin: 0 auto
  &_title
    margin-bottom: 20px
    font-size: 24px
    font-weight: 600
    text-align: center
  &_tabs
    display: flex
    margin-bottom: 20px
    &_tab
      display: inline-flex
      width: 50%
      padding: 13px
      border-bottom: 2px solid $grey-light
      font-size: 20px
      color: $grey-medium
      justify-content: center
      text-align: center
      cursor: pointer
      +mt(.2s)
      &_active, &:hover
        color: $sky-blue
        border-bottom-color: $sky-blue
  &_chosen
    &_DropdownMenu
      height: 20px
      width: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      margin-bottom: 20px
      text-align: center
      &_DropdownToggle
        flex-direction: row
        align-items: center
        font-size: 20px
        color: $sky-blue
        &_active, &:hover
          color: $sky-blue
        &_title
          padding: 0 13px
        &_icon
          width: 11px
          margin-left: 7px
          transform: rotate(180deg)
          +mt(.2s)
          fill: $sky-blue
          path
            fill: $sky-blue
      &_DropdownContent
        width: 100%
        padding: 0
        display: flex !important
        flex-direction: row
        align-items: center
        justify-content: space-between
        &_item
          width: 50%
          cursor: pointer
          padding: 13px
          color: $grey-medium
          &:first-child
            flex: 1 1 auto
            border-right: 1px solid $grey-light
          &_active, &:hover
            color: $sky-blue
