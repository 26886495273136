@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 800px
  width: 700px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
.AdminPatientAppointmentView
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 10px 0
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
    &_Label
      color: $light-grey
    &_Status
      &_IN_PROGRESS
        color: $sky-blue
      &_CANCELED
        color: $yellow-accent
      &_DONE
        color: $light-grey
      &_AWAITS
        color: $spring-green
      &_FINISHED
        color: $steel-blue
      &_FAILED
        color: $red
    &_Wrapper
      width: 100%
      height: 100%
      max-height: 600px
      overflow: auto
      display: flex
      flex-direction: column
      flex: 1 1 auto
    &_Item
      padding: 10px
      border-bottom: 1px solid $light-grey
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      &_Container
        align-items: stretch !important
        padding: 10px 20px
      &:last-child
        border-bottom: none
      &_Profile
        display: flex
        align-items: stretch
        justify-content: space-between
        flex-direction: row
        & > div
          padding: 10px 20px
          display: flex
          &:first-child
            align-items: center
          align-items: flex-start
          justify-content: flex-start
          flex-direction: row
          svg
            margin: 5px
            fill: $sky-blue
            path
              fill: $sky-blue
        &_Name
          font-weight: 500
          margin-bottom: 10px
        &_Img
          width: 80px !important
          height: 80px !important
          margin-right: 20px
        &_Id
          color: $grey-light
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close

