@import '../../assets/sass/vars'


.AgreementsPage
  width: 100%
  margin: 0 auto
  display: flex
  height: 100%
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  h3
    color: $sky-blue
  &_List
    display: flex
    flex-direction: column
    margin: 0
    padding: 0
  &_Item
    flex-wrap: nowrap
    margin-bottom: 5px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    .icon
      line-height: 40px
      width: 20px
      height: 40px
      margin-right: 15px
      display: inline-block
      svg
        color: #217bff
        width: 100%
        fill: #217bff
    color: $slate-grey
    font-weight: 500
    &:hover
      color: #217bff
