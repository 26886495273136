.link
  font-size: 14px
  img
    margin-right: 10px
    margin-bottom: 1px
    max-height: 20px
    max-width: 20px
    line-height: 16px
    vertical-align: middle
  &_preview
    box-shadow: 0 0 2px rgba(0,0,0,0.2)
  //&_name
  //  white-space: nowrap
  //  overflow: hidden
  //  text-overflow: ellipsis
