@import '../../../../assets/sass/vars'
.root
  display: flex
  flex-wrap: wrap
  gap: 1rem
  align-items: flex-start
  justify-content: space-between
  padding: 1rem
  &_item
    width: calc(50% - 1rem)
    border-radius: $border-radius-primary
    border: 1px solid $grey-light
    display: flex
    align-items: stretch
    padding: 1rem
    cursor: pointer
    &_data
      flex: 1 1 auto
      width: 100%
      display: flex
      align-items: flex-start
      justify-content: center
      flex-direction: column
      &_name
        color: $slate-grey
        font-weight: 500
        margin-bottom: 0.5rem
      &_text
        color: $slate-grey
    &_actions
      display: flex
      justify-content: center
      align-items: flex-end
  &_button
    margin: 1rem 0
    &_remove
      color: lighten($red, 20%)
      border-color: lighten($red, 20%)
  &_action
    display: flex
    justify-content: center
    align-items: center
    padding: 2rem 0



