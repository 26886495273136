@import '../../../assets/sass/vars'
.PartnersPageAdmin
  &_InfiniteScroll
    width: 100%
  &_Profile
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    &_Name
      color: $slate-grey
      font-weight: 500
      margin-bottom: 10px
    &_Item
      color: $slate-grey
  &_Button
    color: $slate-grey
    margin-bottom: 10px
  &_EmptyLabel
    display: flex
    align-items: center
    justify-content: center
    padding: 8px 16px
  &_Toolbar
    display: flex
    justify-content: stretch
    align-items: center
    padding: 10px 0
    border-bottom: 1px solid $grey-light
    &_File
      opacity: 0
      width: 100%
      height: 100%
      cursor: pointer !important
      position: absolute
    &_Buttons
      width: 100%
      padding: 0 10px
      display: flex
      justify-content: space-between
      align-items: stretch
      button
        height: 100%
        position: relative
        &:first-child
          margin-right: 5px
  &_Row
    border-bottom: 1px solid $light-grey
    padding: 10px 0
    width: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    align-items: flex-start
    &_Main
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: flex-start
    &:last-child
      border-bottom: 1px solid transparent
    &_Cell
      padding: 0 5px
      display: flex
      justify-content: flex-start
      align-items: flex-start
      width: 50%
      &:first-child
        justify-content: flex-start
