@import '../../../assets/sass/vars'

.bttn
  border-radius: 15px
.bttn
  position: relative
.bttn
  display: -webkit-inline-flex
  display: -ms-inline-flexbox
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 9.5px 24px
  color: $black
  border: 1px solid #D3D3D3
  font-size: 20px
  line-height: 24px
  font-family: $base_font
  font-weight: 400
  white-space: nowrap
  text-decoration: none
  background-color: #fff
  cursor: pointer
  +mt(.2s)
  &:hover
    box-shadow: 3px 4px 17px -9px $slate-grey
  &:disabled
    opacity: .6
    cursor: default
  &.bttnOutline
    background-color: transparent
    &:hover
      color: #fff
      svg
        fill: #fff
        path
          fill: #fff

.bttn_sm
  padding: 3.5px 20px
  border-radius: 10px
  font-size: 16px

.bttn_md
  padding: 7.5px 11.5px
  font-size: 16px

.bttn_lg
  padding: 18.5px 11.5px
  font-size: 20px

.bttnWeight_medium
  font-weight: 500

button.bttn
  &:focus
    outline: 0

.bttnBlock
  width: 100%

.bttn_default
  &.bttnOutline
    &:hover
      color: $black
.bttn
  &_default
    background: $white
    border: 1px solid $sky-blue-light
  &_primary
    background: linear-gradient(90deg, #0D64E2 0%, #2197E7 100%)
  &_alert
    border: 1px solid #D25B17
    background: linear-gradient(90deg, #D25B17 0%, #E68B26 100%)
.bttn_primary
  border-color: $sky-blue
  color: $white
  &:hover
    color: $white
    background-color: $sky-blue
    box-shadow: 3px 4px 17px -9px $sky-blue
  &.bttnOutline
    color: $white

.bttn_secondary
  background-color: $yellow-accent
  border-color: $yellow-accent
  color: #fff
  &:hover
    color: #fff
    background-color: $yellow-accent
    box-shadow: 3px 4px 17px -9px $yellow-accent
  &.bttnOutline
    color: $yellow-accent

.bttn_alert
  background-color: $red
  border-color: $yellow-accent
  color: $white
  &:hover
    color: $white
    background-color: $red
    box-shadow: 3px 4px 17px -9px $yellow-accent
  &.bttnOutline
    color: $white

.bttn_raw
  border-color: transparent
  background-color: transparent

@media (min-width: 320px) and (max-width: 768px)
  .bttn
    padding: 3.5px 20px
    border-radius: 10px
    font-size: 16px
    line-height: 34px
