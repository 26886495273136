@import '../../assets/sass/vars'
.root
  display: flex
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  width: 100%
  align-items: center
  justify-content: space-between
  background-color: $white
  input
    background-color: transparent
    border: none
    width: 100%
    padding: 0.8rem 1rem 0.8rem 0
    flex: 1 1 auto
  &_icon
    color: $sky-blue
    font-size: 20px
    margin: 0 1rem
    cursor: pointer
    &_clear
      cursor: pointer
      left: auto !important
      right: 10px !important


