@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 400px
  width: 400px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
.AdminServicePackageView
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    padding: 24px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: flex-start
    overflow: auto
    &_Item
      width: 100%
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      border-bottom: 1px solid $grey-light
      padding: 5px 10px
      &:last-child
        border-bottom: none
      &_Column
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
      &_Key
        color: $light-grey
      &_Value
        color: $slate-grey
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close

