.dropdownContent
	display: none
	min-width: 175px
	padding: 16px
	background-color: #fff
	position: absolute
	top: calc(100% + 8px)
	right: 0
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25)
	border-radius: 8px
	z-index: 999
