@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 480px
  width: 480px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
  @include modal-dialog
  &_Wild
    width: 800px
    min-width: 800px
.BaseDialog
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  &_Header
    @include modal-header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 24px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: flex-start
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
