@import '../../assets/sass/vars'
.SearchPatients
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  border: 3px solid $sky-blue !important
  background-color: $white !important
  width: 100%
  padding: 0px 20px
  border-radius: 15px
  input
    border: none
    background-color: $white !important
    width: 100%
  &_Icon
    width: 23px !important
    height: 23px !important
    margin-right: 10px
    &_Clear
      cursor: pointer
      left: auto !important
      right: 0 !important
  &_Popover
    z-index: 10000
    width: 100%
    max-height: 300px
    position: absolute
    display: flex
    justify-content: flex-start
    align-items: flex-start
    top: 45px
    left: 0
    background-color: $white
    border-radius: 8px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25)
    overflow: auto
    &_List
      width: 100%
      height: 100%
      display: flex
      flex: 1 1 auto
      overflow: auto
      flex-direction: column
      justify-content: stretch
      align-items: center
      li
        cursor: pointer

