@import '../../../assets/sass/vars'
.wrapper
  flex-direction: column
  height: 100%
  width: 100%
  display: flex
  align-items: stretch
  overflow: hidden
  flex: 1 1 auto
.root
  flex-direction: column
  height: 100%
  width: 100%
  background: $white
  margin: 2rem 0
  border-radius: $border-radius-primary
  border: 1px solid $sky-blue-light
  display: flex
  align-items: stretch
  justify-content: center
  &_more
    display: flex
    align-items: center
    justify-content: center
    margin: 2rem 0
