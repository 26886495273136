@import '../../assets/sass/vars'
.root
  background: $white
  border: 1px solid $sky-blue-light
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
  border-radius: $border-radius-primary
  display: flex
  flex-direction: column
  width: 48%
  justify-content: space-between
  position: relative
  @media only screen and (max-width : 768px)
    width: 100%
  &_wrapper
    display: flex
    flex-direction: row
    padding: 1rem
    gap: 1rem
    @media only screen and (max-width : 992px)
      flex-direction: column
  &_about
    display: flex
    align-items: stretch
    flex-direction: column
  &_favorite
    position: absolute
    right: 0.5rem
    top: 0.2rem
    padding: 0.2rem
    display: flex
    justify-content: flex-end
    &_active
      svg
        fill: $red-light !important
        path
          fill: $red-light !important
    svg
      width: 22px
      cursor: pointer
      fill: $sky-blue-light-second
      +mt(.1s)
      path
        fill: $sky-blue-light-second
        +mt(.1s)
      &:hover
        fill: $red-light
        path
          fill: $red-light
  &_avatar
    display: flex
    align-items: flex-start
    justify-items: center
    & > div
      width: 80px
      height: 80px
      border-radius: 100%
      background-size: cover
      background-position: center center
    @media only screen and (max-width : 768px)
      margin: 0 auto 1rem auto
  &_name
    color: $royal-blue
    font-size: 22px
    line-height: 26px
    font-weight: 600
    text-align: left
    margin-bottom: 0.5rem
    @media only screen and (max-width : 768px)
      text-align: center
  &_specialization
    margin-top: 4px
    font-size: 16px
    line-height: 20px
    font-weight: 500
    margin-bottom: 0.5rem
    color: $grey-medium
    @media only screen and (max-width : 992px)
      text-align: center
  &_reviews
    width: 100%
    display: flex
    align-items: stretch
    font-size: 16px
    font-weight: 500
    color: $royal-blue
    @media only screen and (max-width : 992px)
      justify-content: center
    &_seniority
      display: flex
      margin-right: 1rem
    &_feedback
      display: flex
      align-items: center
      margin-right: 1rem
  &_actions
    padding: 1rem
    display: flex
    gap: 1rem
    flex-direction: column
