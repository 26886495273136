@import '../../assets/sass/vars'


.doctorDetailsInfo
  padding-bottom: 20px
  text-align: center
  &_MainWrap
    padding-top: 20px
    width: 100%
    margin: 0 auto
    &_Item
      display: flex
      flex-direction: column
      border: 1px solid $sky-blue-light
      box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
      border-radius: 20px
      padding: 1rem 2rem
      margin-bottom: 1rem
      justify-content: flex-start
      align-items: stretch
      width: 100%
      h6
        text-align: center
  &_avatar
    display: inline-block
    width: 100px
    height: 100px
    margin-bottom: 15px
    border-radius: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
  &_name
    font-size: 20px
    line-height: 24px
    font-weight: 600
    color: $royal-blue
  &_position
    margin-top: 4px
    color: $grey-medium
    font-size: 16px
    line-height: 20px
  &_rate
    margin: 20px 0
  &_btn
    width: 350px
    font-size: 16px

.reviewCard
  border: 1px solid $grey-light
  border-radius: 12px
  display: flex
  flex-direction: column
  justify-content: stretch
  &_header
    display: flex
    justify-content: space-between
    margin-bottom: 14px
    @media only screen and (max-width : 480px)
      flex-direction: column
      align-items: center
  &_main
    padding: 1rem
    flex: 1 1 auto
    border-top-left-radius: 12px
    border-top-right-radius: 12px
  &_date
    font-size: 17px
  &_rate
    margin-left: 20px
    @media only screen and (max-width : 480px)
      margin-left: 0
  &_comment
    font-size: 16px
    line-height: 20px
    overflow: hidden
  &_author
    position: relative
    display: flex
    align-items: center
    border-top: 1px solid $grey-light
    border-bottom-left-radius: 12px
    padding: 1rem
    &_avatar
      top: 12px
      left: 20px
      display: inline-block
      width: 40px
      height: 40px
      border-radius: 100%
      background-size: cover
      background-repeat: no-repeat
      background-position: center center
      margin-right: 1rem

.reviewCardsCarouselWrap
  position: relative
  padding-bottom: 38px
  .reviewCardSlideDots
    position: absolute
    bottom: -9px
    width: 100%

.reviewCardSlide
  margin: 0 10px
  width: 50%
  height: 100%
  @media only screen and (max-width : 480px)
    width: 100%

#reviewCardSlideBtnLeft
  left: 9px

#reviewCardSlideBtnRight
  right: 9px

.reviewCardSlideBtn
  position: absolute
  bottom: -6px
  display: inline-flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  border-radius: 100%
  cursor: pointer
  background-color: rgba($sky-blue, .5)
  +mt(.2s)
  z-index: 1
  &:hover
    background-color: $sky-blue
  &_icon
    width: 7px
    fill: #fff
    path
      fill: #fff

button:global(.BrainhubCarousel__thumbnail)
  background-color: transparent

.reviewCardSlideDot
  width: 8px
  height: 8px
  border-radius: 100%
  background-color: rgba($sky-blue, .5)

.doctorCert
  height: 270px
  opacity: 0
.doctorCertWrapper
  width: 100%
  height: 100%
  background-repeat: no-repeat
  background-position: center center
  background-size: cover


@media only screen and (max-width : 790px)
  .doctorDetailsInfo_btn
    width: 100%
