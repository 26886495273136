@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  background-color: transparent !important
  box-shadow: none !important
.AdminCancellationEdit
  width: 386px
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    height: auto
    min-height: 0
    padding: 0
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close
  &_Popover
    margin-top: 20px
    &_Date
      height: auto
      width: auto
      padding: 0
    &_Time
      height: 200px
      width: 120px
      padding: 0
      &_SetTime
        text-align: center
        border-radius: 5px
        cursor: pointer
        margin: 2px 0
        padding: 2px 5px
        color: $slate-grey
        &_Disabled
          cursor: not-allowed
          color: $grey-light
        &_Active
          background-color: lighten($sky-blue, 45%)

  &_AccessTimeIcon
    color: $slate-grey
    margin: 0 10px
    font-size: 20px
    width: 20px
    height: 20px
  &_Time
    color: $slate-grey
    font-size: 16px
    border-radius: 5px
    padding: 2px 5px
    &_Toggle
      cursor: pointer
    &_Current
      background-color: lighten($sky-blue, 45%)

