@import '../../../../assets/sass/vars'
.ServicePackagesAdminMfo
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  flex: 1 1 auto
  height: 100%
  width: 100%
  &_Button
    margin: 5px 0
    &_Remove
      color: lighten($red, 20%)
      border-color: lighten($red, 20%)
  &_Toolbar
    display: flex
    justify-content: center
    align-items: center
    padding: 10px 0
    flex-wrap: wrap
    &_Search
      position: relative
      padding: 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px
      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important
    &_Button
      width: 259px
  &_Action
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 0
  &_Content
    display: flex
    flex: 1 1 auto
    height: 100%
    flex-direction: column
    align-items: center
    justify-content: center
  &_Section
    width: 100%
    border-bottom: 1px solid $grey-light
    &:last-child
      border-bottom: none
  &_Items
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    height: 100%
    width: 100%
    flex: 1 1 auto
    &_Docs
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between
    &_Wrapper
      display: flex
      flex-direction: column
      border: 1px solid $grey-light
      border-radius: 8px
      margin-bottom: 10px
    @media (max-width: 480px)
      padding: 10px
    padding: 10px
    &_Item
      width: 100%
      display: flex
      justify-content: space-between
      align-items: stretch
      padding: 5px 10px
      margin-bottom: 10px
      cursor: pointer
      &_Data
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        &_Name
          color: $slate-grey
          font-weight: 500
          margin-bottom: 5px
        &_Price
          color: $slate-grey
        &_Status
          &_NEW
            color: $sky-blue
          &_ACTIVE
            color: $spring-green
          &_ARCHIVE
            color: $grey-light
      &_Actions
        display: flex
        justify-content: center
        align-items: flex-start



