@import '../../assets/sass/vars'


.servicePackageView
  max-width: 360px
  margin: 0 auto
  &_package
    border: 1px solid $grey-light
    border-radius: 12px
    &_title
      padding: 16px 20px
      text-align: center
      color: #000
      font-weight: 500
      &:not(:last-child)
        border-bottom: 1px solid $grey-light
    &_row
      padding: 16px 20px
      font-size: 16px
      color: #000
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      &_title
        margin-bottom: 4px
        color: $grey-medium
        font-size: 16px
        line-height: 20px
        font-weight: 400
        flex: 0 0 100%
      &_key
        margin: 0
        flex: 0 0 calc(60% - 8px)
        font-size: 18px
        color: #000
        line-height: 22px
        font-weight: 400
      &_value
        flex: 0 0 calc(40% - 8px)
        font-size: 18px
        color: #000
        line-height: 22px
        font-weight: 400
        text-align: right
        margin-left: 16px
      &:not(:last-child)
        border-bottom: 1px solid $grey-light
  &_btn
    margin-top: 40px

.packagePromoForm
  max-width: 350px
  margin: 0 auto
  padding: 10px 0 20px
  text-align: center
  input
    text-align: center


@media only screen and (max-width : 480px)
  .servicePackageView
    &_package
      &_row
        &_key
          margin-bottom: 10px
          flex: 0 0 100%
        &_value
          flex: 0 0 100%
          text-align: left
          margin: 0