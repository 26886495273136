@import '../../../../../assets/sass/vars'


.chatFile_Attach
  position: relative
  &:focus
    outline: none
  &_HasFiles
    &::after
      content: ''
      position: absolute
      top: -4px
      right: -9px
      width: 6.5px
      height: 6.5px
      border-radius: 150%
      background-color: $red

.clipIcon
  width: 26px
  height: 28px
  fill: $sky-blue
  cursor: pointer
  path
    fill: $sky-blue
