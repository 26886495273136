@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  flex-direction: column
  border-radius: $border-radius-primary
  border: 1px solid $sky-blue-light
  margin-bottom: 2rem
  overflow: hidden

