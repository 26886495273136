@import '../../assets/sass/vars'
@import '../DoctorDetailsPage/DoctorDetailsPage.module.sass'

.doctorDetailsInfo
  &_Block
    display: flex
    flex-direction: column
    align-items: flex-start
    margin: 2rem 0
    &_Certs
      display: flex
      width: 100%
      flex-wrap: wrap
      @media only screen and (max-width : 768px)
        flex-direction: column
        align-items: stretch
      &_Item
        width: 33%
        @media only screen and (max-width : 768px)
          width: 100%
    h6
      align-items: flex-start
      display: flex
      justify-content: flex-start
      color: $royal-blue
      font-weight: 600
  &_About
    padding: 2rem
    background: $white
    border: 1px solid $sky-blue-light
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    border-radius: 20px
  &:last-child
    border-bottom: none
  p, &_name, li
    color: $black
  li, p
    text-align: left

.doctorCert
  @extend .doctorCert
.doctorCertWrapper
  width: 100%
  @extend .doctorCertWrapper
