@import '../../../assets/sass/vars'
.root
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  align-items: stretch
  flex: 1 1 auto
  padding: 1rem 2rem
  &_header
    display: flex
    align-items: center
    justify-content: space-between
    @media only screen and (max-width : 480px)
      width: 100%
      flex-direction: column
      justify-items: center
      align-items: stretch
  &_title
    font-size: 22px
    color: $sky-blue
    font-weight: 600
    min-width: 150px
    @media only screen and (max-width : 480px)
      margin-bottom: 1rem
      display: flex
      justify-content: flex-start
  &_toolbar
    margin-top: 2rem
    display: flex
    align-items: center
    justify-content: space-between
    @media only screen and (max-width : 480px)
      align-items: flex-start
  &_last
    display: flex
    flex-direction: column
    @media only screen and (max-width : 480px)
      margin-bottom: 1rem
      align-items: flex-start
    &_title
      color: $sky-purple
      font-weight: 500
      font-size: 16px
    &_data
      display: flex
      margin: 1rem 0
      line-height: 40px
      align-items: baseline
      @media only screen and (max-width : 480px)
        flex-direction: column
        line-height: 30px
        margin: 0.1rem
        &_line
          flex-direction: row
      &_values
        display: flex
        align-items: baseline
      &_value
        color: $sky-purple
        font-weight: 600
        font-size: 40px
        @media only screen and (max-width : 480px)
          font-size: 30px
        &_dot
          color: $sky-purple
          font-weight: 600
          font-size: 40px
          margin: 0 0.5rem
          @media only screen and (max-width : 480px)
            font-size: 30px
      &_unit
        color: $sky-purple
        font-weight: 500
        font-size: 16px
        margin-left: 1rem
        @media only screen and (max-width : 480px)
          margin-left: 0
    &_date
      color: $sky-purple
      font-weight: 500
      font-size: 16px
