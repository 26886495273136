@import '../../../assets/sass/vars'


.fg-input, input.MuiInputBase-input
  display: inline-block
  width: 200px
  padding: 12.1px 20px
  color: $black
  background-color: #fff
  font-size: 16px
  line-height: 16px
  border: 1px solid $sky-blue-light
  border-radius: 12px
  box-shadow: none
  appearance: none
  +mt(.2s)
  &.is_searchBar
    background-color: $white
    &::-webkit-input-placeholder
      color: $grey-medium
  &:not(:disabled):not(.is-invalid):not(:invalid):not(:read-only)
    &:hover
      border-color: lighten($sky-blue, 20%) !important
  &::-webkit-input-placeholder
    font-family: $base_font
    font-weight: 400
    color: $grey-medium
    font-size: 14px
    line-height: 16px
    opacity: 1
  &:placeholder-shown
    border-color: $sky-blue-light
  &:focus, &:active
    outline: none
    border-color: lighten($sky-blue, 20%)
  &:disabled:not(:read-only)
    border-color: $grey-light
    background-color: $grey-bg
  &:read-only:not(.MuiInputBase-input)
    cursor: not-allowed !important
    background-color: $grey-bg !important
    &:focus, &:active
      &:placeholder-shown
        border-color: $grey-light
  &.input-filled
    &:placeholder-shown
      background-color: #F7F6F9
      color: $grey-medium
  &.input-block
    display: block
    width: 100%
  &.is-invalid, &:invalid
    border-color: $red !important

input.MuiInputBase-input
  padding: 10.2px 20px


textarea
  resize: vertical
  font-family: $base_font
  &.fg-input
    height: auto

input.MuiInputBase-input
  position: relative
  width: 100%
  padding-right: 55px
  &:read-only
    &:hover
      border-color: lighten($black, 60%) !important

.MuiInputBase-input-error
  .MuiInputBase-root
    .MuiInputBase-input
      border-color: $red !important
      &:hover
        border-color: $red !important

.MuiFormControl-root
  width: 100%

.MuiInputAdornment-root
  position: absolute
  opacity: 0
  top: 4px
  right: 4px
  height: 30px

.MuiInput-underline
  &.MuiInputBase-root
    &:before
      content: none
    &:after
      content: ''
      position: absolute
      top: 4px
      right: 5px
      left: unset
      bottom: unset
      width: 30px
      height: 30px
      background-image: url('../../../assets/img/icons/calendar-icon.svg')
      background-repeat: no-repeat
      transform: none
      border: none
      background-size: contain
      background-position: center center

.MuiInputBase-root
  width: 100%
  &.MuiInputBase-root
    input
      cursor: not-allowed !important
      border-color: $grey-light !important
      background-color: inherit
.FormGroup
  &-SearchBar
    margin: 0
    display: flex
    justify-content: space-between
    align-items: center
    &-Icon
      position: absolute
      display: inline-block
      width: 44px !important
      height: 100% !important
      font-size: 12px !important
      line-height: 44px !important
      padding: 5px 10px
      +mt(.2s)
      fill: $slate-grey
      path
        fill: $slate-grey
    input
      padding-left: 44px !important
  &-password
    position: relative
    &-icon
      display: inline-block
      width: 16px
      height: 16px
      font-size: 16px
      line-height: 16px
      position: absolute
      right: 16px
      top: 14px
      cursor: pointer
      transform: rotate(180deg)
      +mt(.2s)
      fill: $grey-light
      path
        fill: $grey-light
      &-active, &:hover
        fill: $slate-grey
        path
          fill: $slate-grey
