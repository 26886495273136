// HelveticaNeue font
@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 100
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Thin.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 100
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.ttf")


@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 200
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 200
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.ttf")


@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 300
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Light.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 300
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.ttf")


@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 400
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Roman.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 400
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Italic.ttf")


@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 500
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Medium.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 500
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.ttf")


@font-face
	font-family: 'HelveticaNeue'
	font-style: normal
	font-weight: 900
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.ttf")

@font-face
	font-family: 'HelveticaNeue'
	font-style: italic
	font-weight: 900
	font-display: auto
	src: url("../../fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.ttf")
