@import '../../assets/sass/vars'


.root
  display: flex
  flex-direction: column
  justify-content: stretch
  &_header
    padding: 0.5rem 0
    &_left, &_right
      width: 100px
    display: flex
    align-items: stretch
    justify-content: space-between
  &_center
    text-align: center
