@import '../../../../../assets/sass/vars'
.root
  display: flex
  flex-direction: column
  align-items: stretch
  margin-top: 2rem
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  &_list
    position: relative
    overflow: inherit
    background: $white
    border: 1px solid $sky-blue-light
    box-shadow: 5px 5px 10px rgba(229, 233, 246, 0.4)
    border-radius: 0 0 20px 20px
    flex: 1 1 auto
    min-height: 100%
    &_loading
      width: 100%
      height: 100%
      position: absolute
      padding: 3rem 0
      min-height: 400px
      min-width: 100%
      top: 0
      display: flex
      align-items: center
      justify-content: center
      background: $white
