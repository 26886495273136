@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  width: 90% !important
  max-width: 90% !important
  min-height: 600px !important
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden !important
.AdminMfoEdit
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    position: relative
    background: $sky-blue-light-second
    border-bottom: 1px solid $sky-blue-light
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
    padding: 1rem
    @include modal-header
    h6
      margin: 0
      text-align: center
      flex: 1 1 auto
  &_Container
    width: 100%
    display: flex
    flex-direction: column
    padding: 1rem
  &_Item
    margin-bottom: 1rem
    display: flex
    flex-direction: column
    gap: 0.5rem
    &_Row
      align-items: center
      justify-content: space-between
      flex-direction: row
  &_Content
    width: 100%
    padding: 10px 0
    overflow: hidden
    display: flex
    &_Loading
      width: 100%
      height: 100%
      align-items: center
      justify-content: center
      flex: 1 1 auto
    form
      width: 100%
      display: flex
      flex-direction: column
      align-items: stretch
    &_Wrapper
      width: 100%
      overflow: auto
      display: flex
      flex-direction: row
      flex: 1 1 auto
      margin-bottom: 1rem
      align-items: stretch
      justify-content: space-between
  &_CloseIcon
    @include modal-close
    color: $slate-grey
    font-size: 16px

