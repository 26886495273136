.d-none
  display: none

.d-inline
  display: inline

.d-inline-block
  display: inline-block

.d-block
  display: block

.d-table
  display: table

.d-table-row
  display: table-row

.d-table-cell
  display: table-cell

.d-flex
  display: -webkit-box
  display: -ms-flexbox
  display: flex

.d-inline-flex
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex


@media (min-width: 480px)
  .d-xs-none
    display: none

  .d-xs-inline
    display: inline

  .d-xs-inline-block
    display: inline-block

  .d-xs-block
    display: block

  .d-xs-table
    display: table

  .d-xs-table-row
    display: table-row

  .d-xs-table-cell
    display: table-cell

  .d-xs-flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex

  .d-xs-inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex


@media (min-width: 576px)
  .d-sm-none
    display: none

  .d-sm-inline
    display: inline

  .d-sm-inline-block
    display: inline-block

  .d-sm-block
    display: block

  .d-sm-table
    display: table

  .d-sm-table-row
    display: table-row

  .d-sm-table-cell
    display: table-cell

  .d-sm-flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex

  .d-sm-inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex


@media (min-width: 768px)
  .d-md-none
    display: none

  .d-md-inline
    display: inline

  .d-md-inline-block
    display: inline-block

  .d-md-block
    display: block

  .d-md-table
    display: table

  .d-md-table-row
    display: table-row

  .d-md-table-cell
    display: table-cell

  .d-md-flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex

  .d-md-inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex


@media (min-width: 992px)
  .d-lg-none
    display: none

  .d-lg-inline
    display: inline

  .d-lg-inline-block
    display: inline-block

  .d-lg-block
    display: block

  .d-lg-table
    display: table

  .d-lg-table-row
    display: table-row

  .d-lg-table-cell
    display: table-cell

  .d-lg-flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex

  .d-lg-inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex


@media (min-width: 1200px)
  .d-xl-none
    display: none

  .d-xl-inline
    display: inline

  .d-xl-inline-block
    display: inline-block

  .d-xl-block
    display: block

  .d-xl-table
    display: table

  .d-xl-table-row
    display: table-row

  .d-xl-table-cell
    display: table-cell

  .d-xl-flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex

  .d-xl-inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex


.d-print-block
  display: none

@media print
  .d-print-block
    display: block

.d-print-inline
  display: none

@media print
  .d-print-inline
    display: inline

.d-print-inline-block
  display: none

@media print
  .d-print-inline-block
    display: inline-block

@media print
  .d-print-none
    display: none
