@import '../../../../../assets/sass/vars'
@import '../../../../../assets/sass/mixin'
.backdrop
  background-color: transparent !important
.dialog
  max-width: 100% !important
  min-width: 650px !important
  width: 850px !important
  max-height: 950px !important
  height: 99% !important
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden !important
  padding: 1rem !important
  margin: 0 !important
.root
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_text
    display: flex
    flex: 1 1 auto
    align-items: center
    justify-content: center
  &_header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 10px 20px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
  &_controls
    padding: 0 .5rem
    margin: 1rem 0
    flex-direction: column
    align-items: stretch
    display: flex
    flex: 1 1 auto
    overflow: auto
    gap: 1rem
    &_checkbox
      cursor: pointer
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      height: 45px
  &_media
    display: flex
    align-items: center
    justify-content: stretch
    align-content: space-between
    gap: 1rem
  &_close
    color: $slate-grey
    font-size: 16px
    @include modal-close
  &_actions
    margin-top: 2rem
    display: flex
    align-items: center
    justify-content: stretch
    align-content: flex-end

