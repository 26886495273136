@import '../../assets/sass/vars'
.SchedulerDoctor
  &_Calendar
    padding: 0
    width: 100%
    div[class=MuiPickersCalendarHeader-daysHeader]
      margin-top: 2rem
    div[class=MuiPickersCalendarHeader-daysHeader], div[class=MuiPickersCalendar-week]
      padding: 5px 10px
      font-family: $base_font
    div[class=MuiPickersCalendarHeader-switchHeader]
      background: $sky-blue-light-second
      padding: 1rem
      margin: 0
      height: 65px
      border-bottom: 1px solid $sky-blue-light
      svg
        color: $royal-blue
        width: 16px
        height: 16px
        +mt(.2s)
        fill: $royal-blue
        path
          fill: $royal-blue
      p
        font-family: $base_font
        font-weight: 600
        font-size: 16px
        color: $royal-blue

  &_Content
    padding: 0
    background: $white
    border: 1px solid $sky-blue-light
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    border-radius: 20px
    overflow: hidden
  &_Sidebar
    border-right: 1px solid $sky-blue-light
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 100%
    height: 100%
  &_Toolbar
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 1rem 2rem
    background: $sky-blue-light-second
    border-bottom: 1px solid $sky-blue-light
    height: 65px
    &_Date
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: stretch
      text-transform: capitalize
      &_Nav
        background-color: transparent !important
        border: none
        cursor: pointer
        &_Icon
          color: $royal-blue
          width: 9px
          height: 16px
          +mt(.2s)
          fill: $royal-blue
          path
            fill: $royal-blue
        &_Label
          font-weight: 600
          font-size: 16px
          color: $royal-blue
          text-transform: capitalize



