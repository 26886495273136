@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.AttachFile
  position: relative
.AttachFileIcon
  position: absolute
  color: $sky-blue
  left: 10px
  font-size: 30px !important
  height: 30px !important
.DescriptionIcon
  color: $sky-blue
  width: 100% !important
  font-size: 30px !important
  height: 300px !important
.Remove
  cursor: pointer
  border-radius: 50%
  background-color: white
  width: 40px
  height: 40px
  color: $sky-blue
  position: absolute
  right: 20px
  bottom: 20px
  border: 1px solid $sky-blue
  display: flex
  justify-content: center
  align-items: center
  font-size: 25px
.Dialog
  z-index: 10000
  height: auto
  max-width: 100% !important
  min-width: 400px
  width: 400px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
  pointer-events: visible
  @include modal-dialog
.AdminMfoTariffDocEdit
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 10px 0
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
    &_Wrapper
      width: 100%
      height: 100%
      overflow: auto
      display: flex
      flex-direction: column
      flex: 1 1 auto
    &_Item
      padding: 10px
      border-bottom: 1px solid $light-grey
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      &_Container
        align-items: stretch !important
        padding: 0 0 20px 0
      &:last-child
        border-bottom: none
  &_CloseIcon
    cursor: pointer
    color: $slate-grey
    font-size: 16px

