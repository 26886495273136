@import '../../../../assets/sass/vars'
.wrapper
  background: #FFFFFF
  position: absolute
  width: 100%
  ul
    list-style: none
    padding: 10px
    margin: 5px 0 0 0
    border-radius: 10px
    border: 1px solid $grey-light
    li
      margin: 0
      a
        cursor: pointer
        padding: 10px
        display: block
        width: 100%
        border-radius: 10px
        &:hover
          color: #1473ff
          background-color: #CEF4F5
        &.active
          background-color: #1473ff
          color: #fff
