@import '../../../assets/sass/vars'


.fgLabel
	display: block
	margin-bottom: 6px
	font-size: 14px
	color: #000
	&_is_invalid
		color: $red
