.headerLogoWrapper
  display: inline-flex
  align-items: center

.logo
  width: 200px
  height: 80px
  display: inline-block
  vertical-align: middle
  background-size: contain
  background-repeat: no-repeat
  background-position: center center
  background-image: url(../../assets/img/logo.svg)
