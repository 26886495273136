@import '../../assets/sass/vars'


.paymentDetails
  max-width: 335px
  margin: 0 auto
  &_doctor
    margin-bottom: 40px
    text-align: center
    &_avatar
      display: inline-block
      width: 100px
      height: 100px
      margin-bottom: 15px
      border: 1px solid $sky-blue
      border-radius: 100%
      background-size: cover
      background-repeat: no-repeat
      background-position: center center
    &_name
      font-size: 20px
      line-height: 24px
      font-weight: 500
    &_position
      margin-top: 4px
      margin-bottom: 10px
      color: $grey-medium
      font-size: 16px
      line-height: 20px
    &_descr
      color: $grey-medium
      font-size: 16px
      line-height: 20px
  &_details
    margin-bottom: 30px
    &_agree_check
      margin-bottom: 16px
      padding: 12px 20px
      border-radius: 12px
      border: 1px solid $grey-light
    &_promo
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 16px
      padding: 0 20px
      border-radius: 12px
      border: 1px solid $grey-light
      &_btn
        margin: 9px
        margin-right: 0
        padding-left: 14px
        padding-right: 14px
      &_input
        border: none
    &_pay
      display: flex
      justify-content: center
      padding: 12px 20px
      border-radius: 12px
      border: 1px solid $grey-light
      &_item
        width: 33%
        text-align: center
        display: flex
        flex-direction: column
        align-items: center
        &:first-child
          text-align: left
        &:last-child
          text-align: right
      &_title
        display: block
        margin-bottom: 3px
        font-size: 16px
        line-height: 20px
        font-weight: 400
        color: $grey-medium
      &_value
        display: block
        color: #000
        font-size: 18px
        line-height: 22px
        font-weight: 500
  &_paypackage_radio
    padding: 12px 20px
    border-radius: 12px
    border: 1px solid $grey-light
    &_list
      .paymentDetails_paypackage_radio
        &:not(:last-child)
          margin-bottom: 16px


.paymentRequestInfo
  max-width: 460px
  margin: 0 auto
  text-align: center
  &_title
    font-size: 20px
    line-height: 24px
    font-weight: 500
    color: #000
  &_subtitle
    margin-bottom: 40px
    font-size: 18px
    line-height: 22px
    color: $grey-medium
