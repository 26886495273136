@import '../../../../../assets/sass/vars'
@import '../../../../../assets/sass/mixin'
.backdrop
  background-color: transparent !important
.dialog
  max-width: 100% !important
  min-width: 500px
  width: 500px
  height: 400px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
.root
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_text
    display: flex
    flex: 1 1 auto
    align-items: center
    justify-content: center
  &_header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 10px 20px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
  &_close
    color: $slate-grey
    font-size: 16px
    @include modal-close
  &_actions
    display: flex
    align-items: center
    justify-content: stretch
    align-content: space-between
