@import '../../assets/sass/vars'


.contentCard
  border-radius: 12px
  background-color: transparent
  width: 100%
  display: flex
  flex: 1 1 auto
  align-items: stretch
  flex-direction: column
  &_header
    padding: 11.5px 20px
    background-color: transparent
  &_main
    padding: 30px 20px 40px
    background-color: transparent
    border-radius: 12px
    min-height: 35vh
    &::-webkit-scrollbar
      display: none
