@import '../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: center
  &_filters
    display: flex
    background-color: $sky-purple-light
    align-items: stretch
    padding: 0.2rem
    border-radius: $border-radius-primary
    gap: 1rem
    justify-content: space-between
  &_filter
    color: $sky-purple
    background-color: transparent
    font-weight: 500
    font-size: 12px
    min-width: 50px
    @media only screen and (max-width : 480px)
      padding: 0.5rem 0.1rem
    display: flex
    justify-content: center
    cursor: pointer
    padding: 0.5rem 1.5rem
    border-radius: $border-radius-primary
    &_date
      background-color: $sky-purple-light
      min-width: auto
      padding: 0.5rem
      margin-left: 2rem
      @media only screen and (max-width : 480px)
        margin-left: 1rem
      width: 45px
      & > div
        display: none
      &_active
        position: relative
        & > div
          display: block
          width: 10px
          height: 10px
          border-radius: 50%
          position: absolute
          top: -2px
          right: -2px
          background-color: $red
    &_active
      background-color: $white
      color: $sky-blue
