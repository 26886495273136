@import '../../../assets/sass/vars'
.ServicePackagesAdmin
  &_Header
    padding: 0
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    &_Menu
      &_Item
        width: 50%
        &:first-child
          border-top-left-radius: 12px
          border-bottom-left-radius: 12px
        &:last-child
          border-bottom-right-radius: 12px
          border-top-right-radius: 12px
  &_Content
    padding: 0
    display: flex
    align-items: stretch
    flex-direction: column

