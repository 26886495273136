.container 
	width: 100%
	padding-right: 10px
	padding-left: 10px
	margin-right: auto
	margin-left: auto


@media (min-width: 576px) 
	.container 
		max-width: 540px
	

@media (min-width: 768px) 
	.container 
		max-width: 750px
	

@media (min-width: 992px) 
	.container 
		max-width: 970px
	

@media (min-width: 1200px) 
	.container 
		max-width: 1170px


@media (min-width: 1300px) 
	.container 
		max-width: 1280px
	

.container-fluid 
	width: 100%
	padding-right: 10px
	padding-left: 10px
	margin-right: auto
	margin-left: auto


.row 
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-ms-flex-wrap: wrap
	flex-wrap: wrap
	margin-right: -10px
	margin-left: -10px


.no-gutters 
	margin-right: 0
	margin-left: 0


.no-gutters > .col,
.no-gutters > [class*="col-"] 
	padding-right: 0
	padding-left: 0


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto 
	position: relative
	width: 100%
	min-height: 1px
	padding-right: 10px
	padding-left: 10px


.col 
	-ms-flex-preferred-size: 0
	flex-basis: 0
	-webkit-box-flex: 1
	-ms-flex-positive: 1
	flex-grow: 1
	max-width: 100%


.col-auto 
	-webkit-box-flex: 0
	-ms-flex: 0 0 auto
	flex: 0 0 auto
	width: auto
	max-width: none


.col-1 
	-webkit-box-flex: 0
	-ms-flex: 0 0 8.333333%
	flex: 0 0 8.333333%
	max-width: 8.333333%


.col-2 
	-webkit-box-flex: 0
	-ms-flex: 0 0 16.666667%
	flex: 0 0 16.666667%
	max-width: 16.666667%


.col-3 
	-webkit-box-flex: 0
	-ms-flex: 0 0 25%
	flex: 0 0 25%
	max-width: 25%


.col-4 
	-webkit-box-flex: 0
	-ms-flex: 0 0 33.333333%
	flex: 0 0 33.333333%
	max-width: 33.333333%


.col-5 
	-webkit-box-flex: 0
	-ms-flex: 0 0 41.666667%
	flex: 0 0 41.666667%
	max-width: 41.666667%


.col-6 
	-webkit-box-flex: 0
	-ms-flex: 0 0 50%
	flex: 0 0 50%
	max-width: 50%


.col-7 
	-webkit-box-flex: 0
	-ms-flex: 0 0 58.333333%
	flex: 0 0 58.333333%
	max-width: 58.333333%


.col-8 
	-webkit-box-flex: 0
	-ms-flex: 0 0 66.666667%
	flex: 0 0 66.666667%
	max-width: 66.666667%


.col-9 
	-webkit-box-flex: 0
	-ms-flex: 0 0 75%
	flex: 0 0 75%
	max-width: 75%


.col-10 
	-webkit-box-flex: 0
	-ms-flex: 0 0 83.333333%
	flex: 0 0 83.333333%
	max-width: 83.333333%


.col-11 
	-webkit-box-flex: 0
	-ms-flex: 0 0 91.666667%
	flex: 0 0 91.666667%
	max-width: 91.666667%


.col-12 
	-webkit-box-flex: 0
	-ms-flex: 0 0 100%
	flex: 0 0 100%
	max-width: 100%


.order-first 
	-webkit-box-ordinal-group: 0
	-ms-flex-order: -1
	order: -1


.order-1 
	-webkit-box-ordinal-group: 2
	-ms-flex-order: 1
	order: 1


.order-2 
	-webkit-box-ordinal-group: 3
	-ms-flex-order: 2
	order: 2


.order-3 
	-webkit-box-ordinal-group: 4
	-ms-flex-order: 3
	order: 3


.order-4 
	-webkit-box-ordinal-group: 5
	-ms-flex-order: 4
	order: 4


.order-5 
	-webkit-box-ordinal-group: 6
	-ms-flex-order: 5
	order: 5


.order-6 
	-webkit-box-ordinal-group: 7
	-ms-flex-order: 6
	order: 6


.order-7 
	-webkit-box-ordinal-group: 8
	-ms-flex-order: 7
	order: 7


.order-8 
	-webkit-box-ordinal-group: 9
	-ms-flex-order: 8
	order: 8


.order-9 
	-webkit-box-ordinal-group: 10
	-ms-flex-order: 9
	order: 9


.order-10 
	-webkit-box-ordinal-group: 11
	-ms-flex-order: 10
	order: 10


.order-11 
	-webkit-box-ordinal-group: 12
	-ms-flex-order: 11
	order: 11


.order-12 
	-webkit-box-ordinal-group: 13
	-ms-flex-order: 12
	order: 12

.order-unset
	-webkit-box-ordinal-group: unset
	-ms-flex-order: unset
	order: unset


.offset-1 
	margin-left: 8.333333%


.offset-2 
	margin-left: 16.666667%


.offset-3 
	margin-left: 25%


.offset-4 
	margin-left: 33.333333%


.offset-5 
	margin-left: 41.666667%


.offset-6 
	margin-left: 50%


.offset-7 
	margin-left: 58.333333%


.offset-8 
	margin-left: 66.666667%


.offset-9 
	margin-left: 75%


.offset-10 
	margin-left: 83.333333%


.offset-11 
	margin-left: 91.666667%


@media (min-width: 480px) 
	.col-xs 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-xs-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-xs-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-xs-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-xs-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-xs-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-xs-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-xs-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-xs-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-xs-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-xs-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-xs-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-xs-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-xs-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-xs-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-xs-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-xs-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-xs-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-xs-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-xs-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-xs-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-xs-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-xs-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-xs-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-xs-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-xs-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-xs-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-xs-0 
		margin-left: 0
	
	.offset-xs-1 
		margin-left: 8.333333%
	
	.offset-xs-2 
		margin-left: 16.666667%
	
	.offset-xs-3 
		margin-left: 25%
	
	.offset-xs-4 
		margin-left: 33.333333%
	
	.offset-xs-5 
		margin-left: 41.666667%
	
	.offset-xs-6 
		margin-left: 50%
	
	.offset-xs-7 
		margin-left: 58.333333%
	
	.offset-xs-8 
		margin-left: 66.666667%
	
	.offset-xs-9 
		margin-left: 75%
	
	.offset-xs-10 
		margin-left: 83.333333%
	
	.offset-xs-11 
		margin-left: 91.666667%

	.order-xs-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	


@media (min-width: 576px) 
	.col-sm 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-sm-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-sm-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-sm-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-sm-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-sm-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-sm-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-sm-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-sm-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-sm-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-sm-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-sm-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-sm-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-sm-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-sm-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-sm-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-sm-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-sm-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-sm-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-sm-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-sm-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-sm-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-sm-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-sm-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-sm-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-sm-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-sm-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-sm-0 
		margin-left: 0
	
	.offset-sm-1 
		margin-left: 8.333333%
	
	.offset-sm-2 
		margin-left: 16.666667%
	
	.offset-sm-3 
		margin-left: 25%
	
	.offset-sm-4 
		margin-left: 33.333333%
	
	.offset-sm-5 
		margin-left: 41.666667%
	
	.offset-sm-6 
		margin-left: 50%
	
	.offset-sm-7 
		margin-left: 58.333333%
	
	.offset-sm-8 
		margin-left: 66.666667%
	
	.offset-sm-9 
		margin-left: 75%
	
	.offset-sm-10 
		margin-left: 83.333333%
	
	.offset-sm-11 
		margin-left: 91.666667%

	.order-sm-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	


@media (min-width: 768px) 
	.col-md 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-md-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-md-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-md-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-md-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-md-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-md-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-md-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-md-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-md-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-md-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-md-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-md-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-md-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-md-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-md-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-md-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-md-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-md-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-md-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-md-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-md-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-md-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-md-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-md-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-md-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-md-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-md-0 
		margin-left: 0
	
	.offset-md-1 
		margin-left: 8.333333%
	
	.offset-md-2 
		margin-left: 16.666667%
	
	.offset-md-3 
		margin-left: 25%
	
	.offset-md-4 
		margin-left: 33.333333%
	
	.offset-md-5 
		margin-left: 41.666667%
	
	.offset-md-6 
		margin-left: 50%
	
	.offset-md-7 
		margin-left: 58.333333%
	
	.offset-md-8 
		margin-left: 66.666667%
	
	.offset-md-9 
		margin-left: 75%
	
	.offset-md-10 
		margin-left: 83.333333%
	
	.offset-md-11 
		margin-left: 91.666667%

	.order-md-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	


@media (min-width: 992px) 
	.col-lg 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-lg-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-lg-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-lg-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-lg-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-lg-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-lg-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-lg-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-lg-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-lg-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-lg-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-lg-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-lg-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-lg-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-lg-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-lg-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-lg-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-lg-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-lg-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-lg-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-lg-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-lg-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-lg-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-lg-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-lg-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-lg-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-lg-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-lg-0 
		margin-left: 0
	
	.offset-lg-1 
		margin-left: 8.333333%
	
	.offset-lg-2 
		margin-left: 16.666667%
	
	.offset-lg-3 
		margin-left: 25%
	
	.offset-lg-4 
		margin-left: 33.333333%
	
	.offset-lg-5 
		margin-left: 41.666667%
	
	.offset-lg-6 
		margin-left: 50%
	
	.offset-lg-7 
		margin-left: 58.333333%
	
	.offset-lg-8 
		margin-left: 66.666667%
	
	.offset-lg-9 
		margin-left: 75%
	
	.offset-lg-10 
		margin-left: 83.333333%
	
	.offset-lg-11 
		margin-left: 91.666667%

	.order-lg-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	


@media (min-width: 1200px) 
	.col-xl 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-xl-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-xl-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-xl-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-xl-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-xl-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-xl-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-xl-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-xl-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-xl-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-xl-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-xl-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-xl-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-xl-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-xl-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-xl-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-xl-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-xl-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-xl-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-xl-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-xl-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-xl-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-xl-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-xl-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-xl-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-xl-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-xl-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-xl-0 
		margin-left: 0
	
	.offset-xl-1 
		margin-left: 8.333333%
	
	.offset-xl-2 
		margin-left: 16.666667%
	
	.offset-xl-3 
		margin-left: 25%
	
	.offset-xl-4 
		margin-left: 33.333333%
	
	.offset-xl-5 
		margin-left: 41.666667%
	
	.offset-xl-6 
		margin-left: 50%
	
	.offset-xl-7 
		margin-left: 58.333333%
	
	.offset-xl-8 
		margin-left: 66.666667%
	
	.offset-xl-9 
		margin-left: 75%
	
	.offset-xl-10 
		margin-left: 83.333333%
	
	.offset-xl-11 
		margin-left: 91.666667%

	.order-xl-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	


@media (min-width: 1700px) 
	.col-xxl 
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-webkit-box-flex: 1
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	
	.col-xxl-auto 
		-webkit-box-flex: 0
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: none
	
	.col-xxl-1 
		-webkit-box-flex: 0
		-ms-flex: 0 0 8.333333%
		flex: 0 0 8.333333%
		max-width: 8.333333%
	
	.col-xxl-2 
		-webkit-box-flex: 0
		-ms-flex: 0 0 16.666667%
		flex: 0 0 16.666667%
		max-width: 16.666667%
	
	.col-xxl-3 
		-webkit-box-flex: 0
		-ms-flex: 0 0 25%
		flex: 0 0 25%
		max-width: 25%
	
	.col-xxl-4 
		-webkit-box-flex: 0
		-ms-flex: 0 0 33.333333%
		flex: 0 0 33.333333%
		max-width: 33.333333%
	
	.col-xxl-5 
		-webkit-box-flex: 0
		-ms-flex: 0 0 41.666667%
		flex: 0 0 41.666667%
		max-width: 41.666667%
	
	.col-xxl-6 
		-webkit-box-flex: 0
		-ms-flex: 0 0 50%
		flex: 0 0 50%
		max-width: 50%
	
	.col-xxl-7 
		-webkit-box-flex: 0
		-ms-flex: 0 0 58.333333%
		flex: 0 0 58.333333%
		max-width: 58.333333%
	
	.col-xxl-8 
		-webkit-box-flex: 0
		-ms-flex: 0 0 66.666667%
		flex: 0 0 66.666667%
		max-width: 66.666667%
	
	.col-xxl-9 
		-webkit-box-flex: 0
		-ms-flex: 0 0 75%
		flex: 0 0 75%
		max-width: 75%
	
	.col-xxl-10 
		-webkit-box-flex: 0
		-ms-flex: 0 0 83.333333%
		flex: 0 0 83.333333%
		max-width: 83.333333%
	
	.col-xxl-11 
		-webkit-box-flex: 0
		-ms-flex: 0 0 91.666667%
		flex: 0 0 91.666667%
		max-width: 91.666667%
	
	.col-xxl-12 
		-webkit-box-flex: 0
		-ms-flex: 0 0 100%
		flex: 0 0 100%
		max-width: 100%
	
	.order-xxl-first 
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1
	
	.order-xxl-1 
		-webkit-box-ordinal-group: 2
		-ms-flex-order: 1
		order: 1
	
	.order-xxl-2 
		-webkit-box-ordinal-group: 3
		-ms-flex-order: 2
		order: 2
	
	.order-xxl-3 
		-webkit-box-ordinal-group: 4
		-ms-flex-order: 3
		order: 3
	
	.order-xxl-4 
		-webkit-box-ordinal-group: 5
		-ms-flex-order: 4
		order: 4
	
	.order-xxl-5 
		-webkit-box-ordinal-group: 6
		-ms-flex-order: 5
		order: 5
	
	.order-xxl-6 
		-webkit-box-ordinal-group: 7
		-ms-flex-order: 6
		order: 6
	
	.order-xxl-7 
		-webkit-box-ordinal-group: 8
		-ms-flex-order: 7
		order: 7
	
	.order-xxl-8 
		-webkit-box-ordinal-group: 9
		-ms-flex-order: 8
		order: 8
	
	.order-xxl-9 
		-webkit-box-ordinal-group: 10
		-ms-flex-order: 9
		order: 9
	
	.order-xxl-10 
		-webkit-box-ordinal-group: 11
		-ms-flex-order: 10
		order: 10
	
	.order-xxl-11 
		-webkit-box-ordinal-group: 12
		-ms-flex-order: 11
		order: 11
	
	.order-xxl-12 
		-webkit-box-ordinal-group: 13
		-ms-flex-order: 12
		order: 12
	
	.offset-xxl-0 
		margin-left: 0
	
	.offset-xxl-1 
		margin-left: 8.333333%
	
	.offset-xxl-2 
		margin-left: 16.666667%
	
	.offset-xxl-3 
		margin-left: 25%
	
	.offset-xxl-4 
		margin-left: 33.333333%
	
	.offset-xxl-5 
		margin-left: 41.666667%
	
	.offset-xxl-6 
		margin-left: 50%
	
	.offset-xxl-7 
		margin-left: 58.333333%
	
	.offset-xxl-8 
		margin-left: 66.666667%
	
	.offset-xxl-9 
		margin-left: 75%
	
	.offset-xxl-10 
		margin-left: 83.333333%
	
	.offset-xxl-11 
		margin-left: 91.666667%

	.order-xxl-unset
		-webkit-box-ordinal-group: unset
		-ms-flex-order: unset
		order: unset
	