@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: stretch
  justify-content: space-between
  border-bottom: 1px solid $sky-blue-light
  &:last-child
    border-bottom: none
  &_item
    width: 20%
    color: $royal-blue
    display: flex
    align-items: center
    font-weight: 500
    padding: 1rem

