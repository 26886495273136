@import '../../../assets/sass/vars'
.formControlErrors
  fieldset
    border: 1px solid $red !important
.formControl
  fieldset
    border: 1px solid $grey-light !important
    border-radius: 8px !important
.select
  border: none !important
  color: $grey-medium
  min-height: 41px !important
  border-radius: 8px !important
  box-shadow: none !important
  &:hover, &:focus
    border: none !important
  & > div
    border: none !important
    padding: 12.1px 20px !important
    color: #202020 !important
    background-color: #fff !important
    font-size: 16px !important
    line-height: 16px !important

