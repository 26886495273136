@import '../../assets/sass/vars'


.profileCardHeader
  padding: 0

.contentCard_menu
  &_item
    width: 33.33%

.profileDetails
  max-width: 650px
  margin: 0 auto
  &_avatar
    display: inline-block
    width: 100px
    height: 100px
    margin-bottom: 15px
    border: 1px solid $sky-blue
    border-radius: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: center center

.profileNotificationsView
  max-width: 650px
  margin: 0 auto
  h6
    font-weight: 600
    color: $royal-blue
.profileNotifications_tabs
  display: flex
  justify-content: center
  padding: 5px
  border-radius: 12px
  background-color: $grey-bg
  &_item
    width: 100%
    padding: 5px
    border-radius: 12px
    text-align: center
    cursor: pointer
    font-weight: 500
    &:not(:last-child)
      margin-right: 10px
    &:hover, &_active
      background-color: #fff
      color: $slate-grey
.profileNotificationsView_notificationsEmailList
  display: flex
  width: 100%
  margin-top: 1rem
  flex-direction: column
  align-items: stretch
  justify-content: flex-start
  &_item
    display: flex
    background: $white
    border: 1px solid $sky-blue-light
    border-radius: 20px
    margin-bottom: 1rem
    align-items: center
    padding: 1rem
    justify-content: space-between
    &_label
      font-size: 18px
      cursor: pointer
      font-weight: 400
      color: $grey-light
      &_active
        color: $sky-blue
    & > div
      display: flex
      align-items: center
      margin: 0
      padding: 0
.profileNotificationsView_list
  &_item
    display: flex
    align-items: center
    padding: 10px 5px
    &:not(:last-child)
      border-bottom: 1px solid $grey-light

.profilePromoView
  max-width: 360px
  margin: 0 auto
  padding-top: 10px
  text-align: center
  input
    text-align: center
  p
    color: $royal-blue
    font-weight: 600

.profileDetails_editInput
  &_shared
    &_label
      color: $grey-medium
    display: flex
    flex-direction: column
    &_wrapper
      display: flex
      align-items: center
      justify-content: space-between
      border-radius: $border-radius-primary
      border: 1px solid $sky-blue-light
      padding: 0.5rem 1rem
    input[type=text]
      background-color: $white !important
      pointer-events: none
  position: relative
  input
    padding-right: 60px

  .profileDetails_editInput_checkmark
    position: absolute
    top: 12px
    right: 20px
    display: inline-block
    width: 20px
    height: 20px
    border: 1px solid #979797
    border-radius: 100%
    cursor: pointer
    &_active
      border-width: 6px
      border-color: $sky-blue


@media only screen and (max-width : 790px)
  .contentCard_menu
    flex-wrap: wrap
    &_item
      width: 100%
