@import '../../assets/sass/vars'
.root
  background: $sky-blue-light-second
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  display: flex
  align-items: stretch
  justify-content: space-between
  &_actions
    margin-top: 2rem
    display: flex
    align-items: center
    flex-wrap: nowrap
    gap: 1rem
    @media only screen and (max-width : 768px)
      width: 100%
      flex-direction: column
  &_doctor
    display: flex
    align-items: center
    height: 200px
    padding: 2rem
    &_avatar
      height: 95px
      width: 95px
      background-position: center center
      background-size: cover
      border-radius: 50%
      border: 1px solid $white
  &_info
    display: flex
    align-items: flex-start
    flex-direction: column
    padding: 1rem
    justify-content: center
    @media only screen and (max-width : 768px)
      padding-top: 1rem
      align-items: center
    &_title
      font-weight: 600
      font-size: 22px
      color: $royal-blue
      &_sub
        margin-top: 2rem
        font-weight: 500
        font-size: 16px
        color: $royal-blue
  &_icon
    @media only screen and (max-width : 768px)
      display: none
    svg
      margin: 1rem
      height: 200px
      width: auto


