@import '../../../../assets/sass/vars'
.root
  width: 100%
  display: flex
  flex-direction: column
  align-content: stretch
  &_value
    margin-bottom: 1rem
    color: $royal-blue
    font-weight: 500
    display: flex
    align-items: flex-start
    justify-content: space-between
    cursor: pointer
    gap: 1rem
    border: 1px solid transparent
    padding: 2px
    &:last-child
      margin-bottom: 0
    &:focus
      outline: none
      border-color: $sky-blue-light
    &_checker
      width: 21px
      height: 21px
      border: 2px solid $sky-blue
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      padding: 2px
      &_check
        .root_value_checker_inner
          background-color: $sky-blue !important
      &_inner
        width: 100%
        height: 100%
        content: ''
        border-radius: 100%
        background-color: $white
    &_label
      flex: 1 1 auto
      width: 100%
      display: flex
      align-content: flex-start
  &_wrapper
    border: 1px solid $sky-blue-light
    background-color: $white
    border-radius: $border-radius-primary
    max-height: 400px
    min-height: 200px
    overflow: hidden
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: center
  &_values
    padding: 1rem
    display: flex
    flex: 1 1 auto
    width: 100%
    overflow: auto
    height: 100%
    align-items: stretch
    flex-direction: column
    justify-content: flex-start
  &_desc
    padding: 1rem
    color: $royal-blue
    font-weight: 500
  &_nodata
    color: $grey-medium
    font-weight: 500
    justify-content: center
    width: 100%
    align-items: center
    display: flex
