@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 600px
  width: 600px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
.AdminSpecGroupView
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 24px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: flex-start
    overflow: auto
    &_Wrapper
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      flex: 1 1 auto
    &_Item
      padding: 10px
      border-bottom: 1px solid $light-grey
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      &_Container
        align-items: stretch !important
        padding: 0 0 20px 0
      &:last-child
        border-bottom: none
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close
