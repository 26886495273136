@import '../../assets/sass/vars'
.Calendar
  overflow: hidden
  &_Arrow_Icon
    color: $grey-light
    width: 11px
    height: 11px
    +mt(.2s)
    fill: $grey-light
    path
      fill: $grey-light
  &_Day
    cursor: pointer
    color: $slate-grey
    border: 1px solid transparent
    &_Today
      background-color: lighten($sky-blue, 45%) !important
      border-color: $sky-blue !important
      color: $sky-blue
      border-radius: 50%
    &_Current
      background-color: $sky-blue !important
      color: $white
      border-radius: 50%
    &_Future, &_Past, &_Disabled
      color: $grey-light
    &_Disabled
      cursor: not-allowed !important
