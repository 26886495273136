@import '../../assets/sass/vars'

.root
  background: $sky-blue-light-second
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  padding: 0.5rem 1rem
  margin: 2rem 0
  display: flex
  flex-direction: row
  align-items: center
  cursor: pointer
  min-height: 80px
  justify-content: center
  &_mobile
    display: none
    @media (max-width: 1200px)
      display: flex
      width: calc(100% - 2rem)
      margin: 0 !important
      height: auto
      flex-direction: column
      align-items: center
      min-height: auto
      .root_icon_avatar
        margin-bottom: 1rem
      & > .root_about
        align-items: center
  &_icon
    display: flex
    margin-right: 1rem
    align-items: stretch
    justify-content: center
    &_avatar
      width: 73px
      height: 73px
      border-radius: $border-radius-primary
      background-position: center center
      background-size: cover
  &_about
    display: flex
    flex: 1 1 auto
    flex-direction: column
    &_title
      font-weight: 500
      color: $sky-blue
      &_sub
        margin-top: 0.5rem
        span
          font-weight: 600
          color: $sky-blue
        color: $sky-purple
