@import '../../assets/sass/vars'
.AgentTokenDialog
  &_Wrapper
    width: 100%
    height: 100%
    overflow: auto
    display: flex
    flex-direction: column
    flex: 1 1 auto

  &_Item
    border-bottom: 1px solid $light-grey
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start

