@import '../../assets/sass/vars'
.AdminServicePackageLimitEdit
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  &_Content
    overflow: auto
    display: flex
    flex-direction: column
    flex: 1 1 auto
    &_Actions
      display: flex
      flex-direction: row
      justify-content: space-around
      align-items: center
      padding: 8px 0
.Remove
  cursor: pointer
  border-radius: 50%
  background-color: white
  width: 40px
  height: 40px
  color: #1473ff
  border: 1px solid #1473ff
  display: flex
  justify-content: center
  align-items: center
  font-size: 25px
