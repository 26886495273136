@import '../../../../assets/sass/vars'
.root
  width: 100%
  padding: 2rem
  @media (max-width: 480px)
    padding: 1rem
  border: 1px solid $sky-blue-light
  background-color: $sky-blue-light-second
  border-radius: 0 0 $border-radius-primary $border-radius-primary
  display: flex
  border-top: none
  align-items: center
  flex-direction: column
  &_actions
    margin: 2rem 0
    width: 100%
    display: flex
    align-items: center
    gap: 1rem
    justify-content: flex-start
    @media (max-width: 480px)
      flex-direction: column
      width: 100%
      align-items: stretch
    &_next
      padding: 1rem 4rem
    &_back
      padding: 1rem 2rem
  &_select
    width: 100%
