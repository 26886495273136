@import '../../assets/sass/vars'


.appointmentsFilterCardHeader
  padding: 0
  border-bottom-left-radius: 12px
  border-bottom-right-radius: 12px

.contentCard_menu
  &_item
    width: 33.33%
    &:first-child
      border-top-left-radius: 12px
      border-bottom-left-radius: 12px
    &:last-child
      border-bottom-right-radius: 12px
      border-top-right-radius: 12px

.appointments_infinitescroll
  overflow: hidden


@media only screen and (max-width : 790px)
  .contentCard_menu
    flex-wrap: wrap
    &_item
      width: 100%
