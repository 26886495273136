@import '../../../../assets/sass/vars'
.SettingsPageAdminTariffs
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  flex: 1 1 auto
  height: 100%
  width: 100%
  &_Toolbar
    display: flex
    justify-content: center
    align-items: center
    padding: 10px 0
  &_Action
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 0
  &_Content
    display: flex
    flex: 1 1 auto
    height: 100%
    flex-direction: column
    align-items: center
    justify-content: center
  &_Section
    width: 100%
    border-bottom: 1px solid $grey-light
    &:last-child
      border-bottom: none
  &_Tariffs
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    height: 100%
    width: 100%
    flex: 1 1 auto
    @media (max-width: 480px)
      padding: 10px
    padding: 10px 20%
    &_Tariff
      border: 1px solid $grey-light
      width: 100%
      display: flex
      border-radius: 8px
      justify-content: space-between
      align-items: stretch
      padding: 5px 10px
      margin-bottom: 10px
      &_Data
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        &_Name
          color: $slate-grey
          font-weight: 500
          margin-bottom: 5px
        &_Price
          color: $slate-grey
        &_Status
          color: $grey-light
          &_Active
            color: $sky-blue
      &_Actions
        display: flex
        justify-content: center
        align-items: center



