@import '../../../../../assets/sass/vars'
.root
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: space-between
  color: $royal-blue
  font-size: 22px
  font-weight: 600
  line-height: 22px
  margin-bottom: 2rem

