@import '../../../assets/sass/vars'
.root
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  align-items: stretch
  &_mui_paper_root
    box-shadow: none !important
  &_popover
    padding: 1rem
    background: $white
    border: 1px solid $sky-blue-light
    border-radius: $border-radius-primary
    &_text
      color: $sky-purple
      font-size: 16px
    &_actions
      margin-top: 2rem
      display: flex
      align-items: stretch
      gap: 1rem
      justify-content: space-between
  &_item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1rem 2rem
    border-top: 2px solid $sky-blue-light
    flex-wrap: nowrap
    gap: 1rem
    @media only screen and (max-width : 480px)
      padding: 1rem 0.5rem
    &_values
      display: flex
      align-items: center
      @media only screen and (max-width : 480px)
        display: none
    &_value
      display: flex
      align-items: center
      color: $royal-blue
      gap: 0.5rem
      &_wrapper
        display: flex
        align-items: center
        min-width: 150px
      &_status
        border-radius: 50%
        width: 30px
        height: 30px
      &_unit
        margin-left: 1rem
      &_data
        display: flex
        justify-content: flex-start
        font-weight: 500
        font-size: 18px
        @media only screen and (max-width : 480px)
          margin-right: 0.1rem
          display: flex
          flex-direction: column
      &_dot
        margin: 0 1rem
        @media only screen and (max-width : 480px)
          margin: 0 .5rem
    &:last-child
      border-bottom: none
    &_date
      min-width: 250px
      color: $royal-blue
      font-weight: 500
      font-size: 18px
      white-space: nowrap
      & > .root_item_values
        display: none
      @media only screen and (max-width : 480px)
        flex: 1 1 auto
        width: 100%
        & > div:first-child
          margin-bottom: 1rem
        & > .root_item_values
          display: flex
          flex-wrap: wrap
    &_action
      cursor: pointer
      padding: 0.5rem
      svg
        width: 16px
        height: 16px
    &_data
      display: flex
      flex: 1 1 auto
      width: 100%
      align-items: stretch
      flex-direction: column
      @media only screen and (max-width : 480px)
        display: none
    &_desc
      font-size: 18px
      display: flex
      color: $royal-blue
      justify-content: flex-start
      margin-bottom: 1rem
      word-break: break-all
      @media only screen and (max-width : 480px)
        display: none
      &_text
        margin-bottom: 0
    &_actions
      display: flex
      align-items: center
      justify-content: flex-end
      gap: 0.5rem
