@import '../../../../assets/sass/vars'
.root
  width: 100%
  border: 1px solid $sky-blue-light
  background-color: $sky-purple-second
  border-radius: $border-radius-primary $border-radius-primary 0 0
  display: flex
  border-bottom: none
  justify-content: flex-start
  align-items: center
  overflow: hidden
  &_inner
    height: 20px
    background-color: $sky-blue-light3
    transition: width 1s ease-in-out
