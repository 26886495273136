@import '../../assets/sass/vars'

.root
  background: $sky-blue-light-second
  border: 1px solid $sky-blue-light
  border-radius: $border-radius-primary
  margin-bottom: 2rem
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: space-between
  position: relative
  &_activeTariff
    font-weight: 400
    text-align: center
    padding: 5px 0
    background-color: $red-light-second
    color: $red-light
    line-height: 30px
    font-size: 16px
    border-radius: 0 0 $border-radius-primary $border-radius-primary
    &_active
      background: rgba(45, 255, 0, 0.15)
      color: #1FB600
  &_profile
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 2rem
    &_avatar
      margin-top: -45px
      width: 90px
      height: 90px
      border: 2px solid $white
      border-radius: 50%
      background-size: cover
      background-repeat: no-repeat
      background-position: center center
    &_name
      margin: 10px 5px 0 5px
      font-size: 20px
      line-height: 24px
      color: $royal-blue
      font-weight: 600
      text-align: center
    &_balance
      margin: 1rem 0
      font-size: 16px
      color: $sky-purple
      font-weight: 500
      &_value
        font-weight: 500
        color: $sky-blue
