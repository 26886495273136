@import '../../assets/sass/vars'

.root
  padding: 5px 5px
  font-size: 13px
  font-weight: 500
  margin-right: 10px
  color: $light-grey
  display: flex
  align-items: center
