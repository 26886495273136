@import '../../assets/sass/vars'

.root
  cursor: pointer
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 20px
  background: $white
  border: 1px solid $sky-blue-light
  box-sizing: border-box
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
  border-radius: 20px
  min-height: 132px
  width: 100%
  margin-bottom: 20px
  &_content
    padding: 0
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    @media only screen and (max-width : 768px)
      flex-direction: column
  &:hover
    .root_name
      color: $royal-blue
  &_icon
    width: 60px
    margin-right: 1rem
    display: flex
    align-items: center
    @media only screen and (max-width : 768px)
      margin: 0 0 1rem 0
  &_info
    display: flex
    flex-direction: column
    flex: 1 1 auto
    width: 100%
    align-items: flex-start
    @media only screen and (max-width : 768px)
      align-items: center
      margin-bottom: 2rem
  &_name
    font-size: 20px
    line-height: 20px
    font-weight: 600
    color: $royal-blue
    +mt(.2s)
    @media only screen and (max-width : 768px)
      text-align: center
  &_about
    cursor: pointer
    display: flex
    align-items: center
    margin-top: 0.5rem
    font-weight: 500
    font-size: 16px
    color: $sky-blue
    border-bottom: 1px dotted $sky-blue
  &_price
    padding: 0 2rem
    width: 40%
    display: flex
    align-items: center
    @media only screen and (max-width : 768px)
      width: 100%
      margin-bottom: 2rem
      justify-content: center
    h6
      font-weight: 600
      color: $royal-blue
      font-size: 22px
      @media only screen and (max-width : 768px)
        text-align: center
  &_action
    width: 180px
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    @media only screen and (max-width : 768px)
      width: 100%
    button
      white-space: inherit !important
  &_btn
    margin-left: 21px
    color: $sky-blue
    font-size: 16px
    line-height: 20px
    cursor: pointer
    &:hover
      text-decoration: underline
