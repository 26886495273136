@import '../../assets/sass/vars'


.DoctorStatus
  display: inline-block
  align-items: center
  width: 12px
  height: 12px
  border-radius: 50%
  &_duty
    background-color: $spring-green
  &_busy
    background-color: $yellow-accent
  &_not_duty
    background-color: $red
  &_away
    background-color: $light-grey

