@import '../../../../assets/sass/vars'


.root
  max-height: 530px
  width: 250px
  border-right: 1px solid $sky-blue-light
  display: flex
  flex-direction: column
  overflow-y: auto
  @media only screen and (max-width : 480px)
    display: none
  &_title
    display: flex
    justify-content: center
    font-weight: 600
    color: $sky-blue
    padding: 1rem 0
    font-size: $base_font_size
    border-bottom: 1px solid $sky-blue-light
  &_label
    margin-top: 1rem
    font-weight: 600
    color: $royal-blue
    font-size: $base_font_size
  &_item
    margin-top: 1rem
    &_name
      color: $grey-light
    &_value
      font-weight: 500
      color: $royal-blue
