@import '../../../../../assets/sass/vars'


.employee
  &_Avatar
    &_EditMenu
      position: absolute
      bottom: 30px
      right: 30px
      &_List
        margin: 0
        padding: 0
        list-style-type: none
        &_Item
          button
            font-size: 14px
            line-height: 16px
            display: block
            border: none
            background-color: transparent
            cursor: pointer
            color: $black
            +mt(.2s)
            &:hover
              color: $sky-blue
          &:not(:last-child)
            margin-bottom: 10px
      &_Btn
        position: absolute
        display: inline-flex
        align-items: center
        justify-content: center
        width: 40px
        height: 40px
        border-radius: 100%
        background-color: $sky-blue
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12)
        +mt(.2s)
        &:hover
          box-shadow: none
        &_Icon
          width: 12px
          fill: #fff
          path
            fill: #fff
