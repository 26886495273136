@import '../../assets/sass/vars'

$header_height: 80px
$layout_top_padding: 40px

.root
  width: 360px
  padding: 0
  margin-right: 20px
  @media only screen and (max-width : 1200px)
    display: none
    &_admin
      display: block
