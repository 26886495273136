@import '../../assets/sass/vars'


.userProfileBar
  display: inline-flex
  align-items: center
  justify-content: center
  margin-left: 20px
  .profileName
    margin-right: 12px

.profileImg
  width: 40px
  height: 40px
  border: 1px solid $grey-light
  border-radius: 100%
  background-size: cover
  background-repeat: no-repeat
  background-position: top center
  text-decoration: none

.profileName
  font-size: 16px
  line-height: 20px
  text-decoration: none
  color: $sky-blue
  +mt(.2s)

.profileMenuDropdownMenu
  &:global(.dropdownMenuOpen), &:hover
    .profileMenuDropdownToggle
      background-color: lighten($sky-blue, 50%)

.profileMenuDropdownToggle
  display: flex
  align-items: center
  padding: 20px 12px
  position: relative
  background-color: transparent !important
  color: $sky-blue
.profileMenuDropdown
  padding: 10px
  border-radius: 12px
  min-width: 219px
.profileDoctorMenuDropdown
  @extend .profileMenuDropdown
  padding: 10px 0
.profileMenuIcon
  margin-left: 12px
  width: 11px
  transform: rotate(180deg)
  +mt(.2s)
  fill: $sky-blue
  path
    fill: $sky-blue

.userProfile
  display: none
  margin-bottom: 15px
  &_balance
    margin-bottom: 10px
    text-align: center
    &_val
      display: block
      margin-top: 5px
      color: $sky-blue
      font-size: 20px
.menuList
  margin: 0
  padding: 0
  list-style-type: none
  text-align: left
  li
    &:not(:last-child)
      margin-bottom: 5px
.menuListDoctor
  @extend .menuList
  li
    padding: 0 10px
    &:first-child
      border-bottom: 1px solid $light-grey
      margin-bottom: 5px
      padding-bottom: 5px
    &:last-child
      border-top: 1px solid $light-grey
      margin-top: 5px
      padding-top: 5px
    &:not(:last-child)
      margin-bottom: 0px

.menuItem
  display: flex
  align-items: center
  padding: 6px 12px
  cursor: pointer
  text-decoration: none
  border-radius: 10px
  color: $sky-blue
  &:hover, &_active
    background-color: $sky-blue-light
.menuItemDoctor
  @extend .menuItem
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
.menuItemDoctorLabel
  cursor: default !important
  &:hover, &_active
    background-color: transparent !important
.menuItemIcon
  margin-right: 12px
  width: 20px
  height: 20px
  fill: $sky-blue
  path
    fill: $sky-blue
.menuItemIcon2
  margin-right: 12px
  width: 20px
  height: 20px
  path
    stroke: $sky-blue
.menuItemIcon3
  margin-right: 12px
  width: 20px
  height: 20px
  path
    stroke: $sky-blue


@media only screen and (max-width : 1200px)
  .userProfile
    display: block

@media only screen and (max-width : 480px)
  .userProfileBar
    .profileName
      display: none
