@import '../../../assets/sass/vars'
.ReviewsPageAdmin
  &_InfiniteScroll
    width: 100%
  &_Buttons
    display: flex
    width: 100%
    height: 100%
    flex: 1 1 auto
    flex-direction: column
    align-items: stretch
    justify-content: space-between
    button
      &:first-child
        margin-bottom: 20px
  &_Profile
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 10px
    padding: 0 10px 0 0
    &_Data
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center
    &_Name
      color: $slate-grey
      font-weight: 500
      margin-right: 10px
    &_Item
      color: $slate-grey
    &_Date, &_Label
      color: $grey-light
    &_Doctor
      width: 100%
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
    &_Review
      color: $slate-grey
      margin-bottom: 0px
    &_Status
      margin-bottom: 20px
      &_AWAITS
        color: $spring-green
      &_APPROVED
        color: $steel-blue
      &_REJECTED
        color: $red
  &_Row
    border: 1px solid $light-grey
    padding: 10px 0
    width: 100%
    border-radius: 8px
    margin-bottom: 20px
    display: flex
    flex-direction: column
    justify-content: stretch
    align-items: flex-start
    cursor: pointer
    &_Main
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: flex-start
      padding: 10px 0 10px 0
      border-bottom: 1px solid $light-grey
      &:last-child
        border-bottom: 1px solid transparent
    &_Cell
      padding: 0 10px
      display: flex
      flex-direction: column
      justify-content: flex-end
      align-items: flex-start
      width: 100%
  &_Button
    color: $slate-grey
  &_Toolbar
    display: flex
    justify-content: stretch
    align-items: center
    padding: 10px 0
    border-bottom: 1px solid $grey-light
    &_DropdownContent
      padding: 0
      &_Calendar
        padding: 5px
      ul
        list-style: none
        margin: 0
        padding: 5px
        li
          min-width: 290px
          min-height: 32px !important
          & > div
            display: flex
            justify-content: flex-start
            align-items: center
            flex: 1 1 auto
            width: 100%
            height: 100%
            margin-right: 10px
          position: relative
          display: flex
          justify-content: space-between
          align-items: center
          padding: 5px 10px
          border-radius: 8px
          cursor: pointer
          &:hover
            background-color: lighten($sky-blue, 50%)
      &_Status
        display: block
        width: 12px
        height: 12px
        border-radius: 50%
        &_AWAITS
          background-color: $spring-green
        &_APPROVED
          background-color: $steel-blue
        &_REJECTED
          background-color: $red
      &_Icon
        visibility: visible
        width: 22px !important
        height: 22px !important
        margin-left: 20px
        color: $sky-blue
        fill: $sky-blue
        path
          fill: $sky-blue
        &_Hidden
          visibility: hidden
      &_Reset
        padding: 5px
        border-top: 1px solid $grey-light
    &_Buttons
      padding: 0 10px
      display: flex
      justify-content: space-between
      align-items: stretch
      button
        height: 100%
        position: relative
        &:first-child
          margin-right: 5px
      &_Status
        border-radius: 50%
        background-color: $red
        top: -2px
        right: -2px
        position: absolute
        width: 10px
        height: 10px
    &_Search
      position: relative
      padding: 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px
      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important


