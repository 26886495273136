@import '../../../assets/sass/vars'
.root
  display: flex
  align-items: center
  margin-bottom: 2rem
  justify-content: center
  width: 100%
  min-height: 300px
  padding: 0.1rem
  position: relative
  svg
    overflow: inherit
  &_area
    position: absolute !important
