@import '../../assets/sass/vars'
.Scheduler
  width: 100%
  display: flex
  table:first-child
    td
      span
        color: $royal-blue !important
        font-family: $base_font !important
        font-weight: 600
  &_TimeCell
    z-index: 0
    position: relative !important
    user-select: none !important
    vertical-align: top !important
    padding: 0 !important
    line-height: 48px
    height: 48px
    width: 120px
    border-left: 1px solid $sky-blue-light !important
    border-bottom: 1px solid $sky-blue-light !important
    &_Line
      width: calc(100% + 5px)
      z-index: 1000
      left: -5px
      position: absolute
      display: flex
      align-items: center
      justify-content: space-between
      &_Left
        width: 10px
        height: 10px
        background-color: $lime-green
        border-radius: 50%
      &_Right
        width: 100%
        display: flex
        flex: 1 1 auto
        background-color: $lime-green
        height: 4px
    &_Content
      width: 100%
      height: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      &_Loader
        width: 100%
        height: 100%
      &_Title
        width: 100%
        height: 100%
        flex: 1 1 auto
        position: relative
        &_Label
          font-size: 10px
          left: 0
          top: 0
          font-weight: bold
          padding: 2px
          position: absolute
          color: $slate-grey
          z-index: 1000
          p
            white-space: nowrap
      &_Actions
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 0 5px
        &_Button
          padding: 2px
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          background-color: transparent
          border-radius: 2px
          cursor: pointer
          width: 24px
          height: 24px
          color: $sky-blue
          &_Icon
            pointer-events: none
            width: 20px
            height: 20px
            fill: $sky-blue
            path
              fill: $sky-blue
    &:hover
      background-color: transparent !important
      cursor: pointer !important
    &:first-child
      border-left: none !important
    &:last-child
      padding-right: 0 !important
    tr:last-child &
      border-bottom: none !important
    &:focus
      background-color: transparent !important
      outline: none !important
    &_Active
      border-left: 2px solid $lime-green !important
      background-color: $lime-green-light !important
      border-right: 2px solid $lime-green !important
      &_First
        border-radius: 6px 6px 0 0
        height: 2px
        width: 100%
        background-color: $lime-green
      &:first-child
        border-left: 2px solid $lime-green !important
      &:focus
        background-color: $lime-green-light !important
      &:hover
        background-color: $lime-green-light !important
  &_DayScaleCell
    width: 120px
    cursor: pointer !important
    border-bottom: none !important
    &_Day
      width: 26px
      height: 26px
      padding: 2px
      font-size: 15px
      color: $royal-blue
      font-weight: 600
      font-family: $base_font
      &_Current
        background-color: $sky-blue
        border-radius: 50%
        color: $white
        align-items: center
        display: flex
        justify-content: center
        padding: 5px
        width: 32px
        height: 32px
      &_Week
        width: 16px
        height: 18px
        font-size: 12px
        text-align: center
        color: $grey-light
        margin-bottom: 2px

