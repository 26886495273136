@import '../../../assets/sass/vars'


.fgSelectControl
  display: inline-block
  color: $grey-medium
  font-size: 14px
  line-height: 16px
  border: 1px solid $sky-blue-light !important
  padding: 3.1px 20px
  border-radius: 12px !important
  box-shadow: none !important
  +mt(.2s)
  &:hover
    &:not(.fgSelectControl_readonly):not(.fgSelectControl_disabled):not(.fgSelectControl_is_invalid)
      border-color: lighten($sky-blue, 20%) !important
  &_is_invalid
    border-color: $red !important
    .fgSelectIndicatorsContainer
      svg
        fill: $red
  &_focused
    border-color: lighten($sky-blue, 20%)
  &_block
    width: 100% !important
  &_readonly
    background-color: #fff !important
    cursor: not-allowed !important
    &.fgSelectControl_has_value
      color: $black !important
      .fgSelectSingleValue
        color: $black !important
  &_disabled
    &:not(.fgSelectControl_readonly)
      cursor: not-allowed
      pointer-events: auto
      border-color: $grey-light !important
      background-color: $grey-bg !important
  &_has_value
    color: $black !important
    border-color: $sky-blue-light !important

.fgSelectIndicatorsContainer
  svg
    fill: $sky-blue

.fgSelectMenu
  overflow: auto
  padding: 10px
  padding-bottom: 5px
  border-radius: 12px !important
  *::-webkit-scrollbar
    width: 7px
    height: 7px

  /* Track */
  *::-webkit-scrollbar-track
    background: $grey-bg

  /* Handle */
  *::-webkit-scrollbar-thumb
    background: $grey-light
    border-radius: 10px
    border: 1px solid lighten($grey-medium, 20%)

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover
    background: darken($grey-light, 10%)

  &_block
    width: 100% !important
  &_short_list
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    max-height: 150px

.fgSelectOption
  margin-bottom: 5px
  padding: 6px 11px
  background-color: #fff !important
  border-radius: 10px
  +mt(.2s)
  &:not(.fgSelectOption_disabled)
    &:hover, &.fgSelectOption_selected
      color: #fff
      background-color: $sky-blue !important
    &:hover
      &:not(.fgSelectOption_selected)
        background-color: lighten($sky-blue, 50%) !important
        color: $sky-blue

  &_selected
      background-color: $sky-blue !important

.fgSelectSeprator
  display: none
