@import '../../../../../assets/sass/vars'
.root
  display: flex
  width: 100%
  background: $sky-blue-light-second
  border: 1px solid $sky-blue-light
  border-bottom: none
  border-radius: 19px 19px 0 0
  justify-content: space-between
  &_cell
    font-size: $base_font_size
    color: $grey-medium
    display: flex
    align-content: flex-start
    align-items: center
    padding: 1rem 2rem
