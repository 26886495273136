@import '../../assets/sass/vars'


.doctorSchedule
  max-width: 360px
  margin: 0 auto
  @media only screen and (max-width : 480px)
    width: 100%
  &_timeList
    display: flex
    flex-wrap: wrap
    justify-content: center
    padding: 0 1rem
    @media only screen and (max-width : 480px)
      padding: 0 3rem
    &_time
      margin-right: 1rem
      margin-bottom: 1rem
      width: 100px
      @media only screen and (max-width : 480px)
        width: 35%
      display: flex
      align-items: center
      justify-content: center
      padding: 6px 12px
      font-size: 16px
      line-height: 20px
      color: $slate-grey
      border: 1px solid $grey-light
      border-radius: 12px
      cursor: pointer
      +mt(.2s)
      &:hover, &_active
        background-color: $sky-blue
        color: #fff
      &_active
        pointer-events: none
