@import '../../../../assets/sass/vars'

.root
  display: flex
  flex-direction: column
  padding: 1rem
  align-items: flex-start
  &_select
    width: 100%
    margin-bottom: 1rem
  &_label
    color: $royal-blue
    font-weight: 500
    margin-bottom: 1rem
  &_subject
    color: $royal-blue
    font-weight: normal
    margin-bottom: 1rem
  &_attachments
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 1rem
    &_item
      color: $sky-blue
      display: flex
      align-items: center
      max-width: 350px
      cursor: pointer
      font-weight: 500
      &_icon
        margin-right: 1rem
      &_name
        white-space: nowrap
        overflow: hidden
        flex: 1 1 auto
        width: 100%
        display: flex
        text-overflow: ellipsis
        border-bottom: 1px solid $sky-blue
  &_files
    display: flex
    align-items: flex-start
    flex-direction: column
    width: 100%
  &_file
    color: $sky-blue
    display: flex
    align-items: center
    cursor: pointer
    margin-bottom: 1rem
    position: relative
    font-weight: 500
    width: 100%
    justify-content: space-between
    &_wrapper
      display: flex
      width: auto
      align-items: center
    &_icon
      margin-right: 1rem
    &_name
      white-space: nowrap
      overflow: hidden
      width: 100%
      display: flex
      text-overflow: ellipsis
      border-bottom: 1px solid $sky-blue
    &_delete
      cursor: pointer
      padding: 1rem
      display: flex
      align-items: center
      &_label
        color: $pink
        margin-left: .5rem
  input[type=file]
    opacity: 0
    left: 0
    top: 0
    cursor: pointer
    position: absolute
    width: 100%
    height: 100%
  input[type=text], textarea
    margin-bottom: 1rem
