// App colors
$sky-blue: #1473FF
$sky-blue-dark: #0D64E2
$sky-blue-light: #B6E0FF
$sky-blue-light2: #F4F9FF
$sky-blue-light3: #5E86ED
$grey-medium: #8A8A8F
$silver: #C4C4C7
$grey-light: #D8D8D8
$grey-bg: #EFEFF4
$slate-grey: #3F3F3F
$red: #FF341A
$red-light: #FF6911
$red-light-second: #FFF3F3
$spring-green: #00C12F

$sky-purple: #8F9DC9
$sky-purple-second:#C8D4F1
$sky-purple-light: #F1F5FF
$orange-accent: #E15A01
$orange-bg: #FFB280
$black: #202020
$white: #ffffff
$yellow-accent: #F5A623
$sky-blue-light-second: #F4F9FF
$purple: #BD64AA

$steel-blue: #0069B4
$royal-blue: #0C2E62
$lime-green: #57CE62
$lime-green-light: #D6FAD9
$orange: #D25B17
$flush-orange: #FF8400
$gold: #F9D400
$beige: #F4F3DF
$honey-dew: #E7F3DF
$black-blue: #282D32
$white-blue: #E9E9E9
$brown: #775043

// Social colors
$fb-color: #3B5998
$tw-color: #55ACEE
$vk-color: #587EA3
$gp-color: #DD4B39
$ok-color: #EE8208

// Shades of Grey
$dim-grey: #282D32
$light-grey: #B3BABD
$ghost-white: #F0F0F0
$white-smoke: #FAFAFA

// Shades of red
$luminous-red: #DE3A1E
$indiang-red: #F44236
$tomato: #F2555B

$pink: #FF0099


// Fonts
$base_font: 'Montserrat', sans-serif
$base_font_size: 16px

//Mixins
@mixin mt($time)
    transition: all $time ease

$layout_max_width: 1170px
$header_height: 80px
$sidebar_width: 260px
$content_padding_left: 29px
$layout_top_padding: 40px


$border-radius-primary: 15px
