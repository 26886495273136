@import '../../../../assets/sass/vars'

.avatar
  position: relative
  display: inline-block
  width: 100px
  height: 100px
  border-radius: 100%
  background-size: cover
  background-repeat: no-repeat
  background-position: top center
  border: 1px solid $sky-blue
  box-sizing: content-box

.menu
  position: absolute
  bottom: -5px
  right: -5px

.list
  margin: 0
  padding: 0
  list-style-type: none

.item
  button
    display: block
    border: none
    background-color: transparent
    cursor: pointer
    color: $black
    +mt(.2s)

    &:hover
      color: $sky-blue

    &:not(:last-child)
      margin-bottom: 10px

.btn
  display: flex
  align-items: center
  justify-content: center
  width: 30px
  height: 30px
  border-radius: 100%
  background-color: $sky-blue
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12)
  +mt(.2s)

  &:hover
    box-shadow: none

.icon
  width: 12px
  fill: #fff

  path
    fill: #fff

.spinner
  background: rgba(255, 255, 255, 0.3)
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  align-items: center
  justify-content: center
