@import '../../../assets/sass/vars'
.root
  display: flex
  justify-content: flex-start
  &_patient
    width: 100%
    display: flex
    flex: 1 1 auto
    align-items: center
    justify-content: center
    &_wrapper
      border-radius: $border-radius-primary
      border: 1px solid $sky-blue
      padding: 1rem
      background-color: $sky-blue-light-second
      color: $sky-blue
  &_patientName
    display: flex
    align-items: center
    color: $sky-blue
