@import '../../assets/sass/vars'


.root
  display: flex
  align-items: center
  font-size: 18px
  color: $sky-blue
  line-height: 22px
  cursor: pointer
  &_icon
    height: 18px
    margin-right: 8px
    fill: $sky-blue
