@import "base"
@import "react-datepicker/dist/react-datepicker.css"
.page-header-menu
  display: flex
  background: $white
  border-top: 1px solid $sky-blue-light
  border-bottom: 1px solid $sky-blue-light
  border-radius: 15px
  gap: 1rem
  @media only screen and (max-width : 480px)
    background: transparent
    border-top: none
    border-bottom: none
  &-item
    &:last-child
      border-right: 1px solid #A1D7FF
    &:first-child
      border-left: 1px solid #A1D7FF
    @media only screen and (max-width : 480px)
      border: 1px solid #A1D7FF
    padding: 13px 30px
    font-size: 18px
    font-weight: 500
    line-height: 22px
    cursor: pointer
    text-align: center
    background: transparent
    border-radius: 15px
    &:hover
      box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    &-active
      color: $sky-blue
      background: $sky-blue-light-second
      border-right: 1px solid #A1D7FF
      border-left: 1px solid #A1D7FF
      border-bottom-color: transparent
      @media only screen and (max-width : 480px)
        border-bottom-color: #A1D7FF

.page-header-back-link
  display: inline-flex
  align-items: center
  font-size: 18px
  color: $sky-blue
  line-height: 22px
  cursor: pointer
  &:hover
    text-decoration: none
  &-icon
    height: 18px
    margin-right: 8px
    fill: $sky-blue
    path
      fill: $sky-blue

.page-header-title
  font-size: 22px
  font-weight: 600
  line-height: 22px
  color: $royal-blue

.rate-stars-list
  text-align: center
  .rate-star
    display: inline-block
    fill: $grey-medium
    transform: scale(0.8)
    path
      fill: $grey-medium
    &:not(:last-child)
      margin-right: 6px
    &-active
      fill: $flush-orange
      path
        fill: $flush-orange
.Calendar
  .MuiPickersCalendar-week,.MuiPickersCalendarHeader-daysHeader
    justify-content: space-between
  .MuiPickersCalendarHeader-switchHeader
    text-transform: capitalize
    color: $slate-grey
.customCalendarWrap
  display: inline-block
  border: 1px solid $grey-light
  border-radius: 12px
  overflow: hidden
  .MuiPickersCalendarHeader
    &-daysHeader
      display: flex
    &-dayLabel
      display: flex
      align-items: center
      justify-content: center
      width: 42px
      height: 42px
      color: #000
      font: $base_font
      font-size: $base_font_size
      font-weight: 500
      &:first-letter
        text-transform: uppercase
  @media only screen and (max-width : 480px)
    width: 100%
  .MuiTypography-body1
    font-weight: 500
    &:first-letter
      text-transform: uppercase
  .MuiPickersCalendarHeader-switchHeader
    border-bottom: 1px solid $grey-light
    margin-bottom: 10px
  .MuiPickersCalendar-transitionContainer
    padding: 0 12px
    padding-bottom: 20px
    @media only screen and (max-width : 480px)
      padding: 0
      padding-bottom: 10px
    > div
      position: static
      display: flex
      flex-direction: column
  .MuiPickersSlideTransition-slideExit, .MuiPickersSlideTransition-slideExitActiveLeft-left
    position: absolute !important
    top: 0
    left: 0
    right: 0
  .MuiPickersDay-current
    .MuiIconButton-label
      border: 1px solid $sky-blue
  .MuiPickersDay-day
    position: relative
    width: 42px
    height: 42px
    display: flex
    flex-direction: column
    .MuiIconButton-label
        width: 24px
        height: 24px
        border-radius: 100%
        margin-bottom: 5px
    &.MuiIconButton-root
      border-radius: 0
      &, &:hover
        background-color: transparent
    &::after
      content: ''
      position: static
      width: 8px
      height: 8px
      background-color: $sky-blue
      border-radius: 100%
  .MuiPickersDay-dayDisabled
    &::after
      background-color: $grey-medium
      opacity: .5
  .MuiPickersDay-daySelected
    .MuiIconButton-label
      background-color: $sky-blue
      color: #fff
      &:hover
        background-color: $sky-blue
.MuiPickersClock-clock
  background-color: $sky-blue-light2 !important
.MuiPickersToolbar-toolbar
  border-radius: $border-radius-primary
  background-color: $sky-blue !important
.MuiPickersClockPointer-thumb
  border: 14px solid $sky-blue !important
.MuiPickersClockPointer-pointer
  background-color: $sky-blue !important
.MuiPickersClockNumber-clockNumber
  color: $sky-purple !important
.MuiPickersClockNumber-clockNumberSelected
  color: $white !important
.Mui-error
  & + .Mui-error
    margin-top: 2px !important
    font-weight: 300 !important
    color: #FF341A !important
    font-size: 12px !important
    opacity: .5 !important
@import "media"
