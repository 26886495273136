@import '../../../assets/sass/vars'
.ReportsPageAdminTab
  &_Header
    padding: 0
    border-radius: 12px 12px 0 0 !important
    &_Menu
      &_Item
        width: 100%
        &:first-child
          border-top-left-radius: 12px
          border-bottom-left-radius: 0
        &:last-child
          border-bottom-right-radius: 0
          border-top-right-radius: 12px
  &_Content
    padding: 0
    display: flex
    align-items: stretch
    flex-direction: column

