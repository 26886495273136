@import '../../assets/sass/vars'


.appointmentChatView
  &_Header
    background-color: $sky-blue-light-second
    border: 1px solid $sky-blue-light
    border-radius: $border-radius-primary
    padding: 1rem
    display: flex
    align-items: center
    justify-content: space-between
    &_Title
      color: $sky-blue
      font-weight: 600
      margin: 0
      flex: 1 1 auto
      display: flex
      justify-content: center
      @media only screen and (max-width : 480px)
        padding: 0 1rem
        text-align: center
  &_CardMain
    padding: 0
.appointmentChat
  &_wrapper
    position: relative
    display: flex
    align-content: space-between
  &_body
    flex: 1 1 auto
    height: 530px
    width: 100%
    overflow-y: auto
    margin-bottom: 20px
    padding: 10px 20px
    &::-webkit-scrollbar
      width: 7px
      height: 7px

    /* Track */

    &::-webkit-scrollbar-track
      background: $grey-bg

    /* Handle */

    &::-webkit-scrollbar-thumb
      background: $grey-light
      border-radius: 10px

    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover
      background: darken($grey-light, 10%)

  &_message
    display: flex

    &:not(:last-child)
      margin-bottom: 20px

    &_avatar
      display: inline-block
      flex: 0 0 41px
      width: 41px
      height: 41px
      margin-bottom: 15px
      border: 1px solid $sky-blue
      border-radius: 100%
      background-size: cover
      background-position: center center

    &_body
      width: 100%
      margin-left: 10px

    &_author
      font-size: 16px
      font-weight: 500
      line-height: 20px
      color: $grey-medium
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 0.5rem
    &_name, &_timestamp
      color: $grey-medium
    &_timestamp
      margin-left: 1rem
      display: flex
      align-content: flex-end
    &_text
      white-space: pre-line
      margin-right: 10px
      font-size: 16px
      line-height: 16px
      color: $royal-blue
      padding: 0.5rem 1rem
      background-color: transparent
      border-radius: 10px

    &_fileList
      display: flex
      align-items: flex-start
      flex-wrap: wrap

      > div
        flex: 0 0 160px

      &_file
        &_doc
          display: inline-block
          margin-right: 15px
          margin-bottom: 15px
          padding: 10px 15px
          padding-left: 43px
          border-radius: 12px
          border: 1px solid $sky-blue
          background-image: url('../../assets/img/icons/pdf-icon.svg')
          background-repeat: no-repeat
          background-size: 21px
          background-position: 10px center
          +mt(.2s)

          &:hover
            color: $sky-blue

        &_image
          height: 120px
          padding: 15px
          background-size: cover
          background-repeat: no-repeat
          background-position: top center
          border-radius: 12px
          border: 1px solid $sky-blue
          font-style: italic
          cursor: pointer
          font-size: 14px
          line-height: 17px
          text-align: center

    &_time
      flex: 0 0 150px
      margin-left: auto
      text-align: right
      font-size: 16px
      line-height: 20px
      color: $grey-medium

  &_input
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding: 10px
    position: relative
    @media only screen and (max-width : 768px)
      flex-wrap: wrap
    &_message
      min-height: 54px
      padding: 15px 50px 2px 10px
      line-height: 24px
      width: 100%
      margin: 0 10px
      max-height: 300px
      background-color: $white !important

    &_attach
      position: absolute
      right: 80px
      bottom: 20px
      padding: 0 10px
      @media only screen and (max-width : 768px)
        right: 20px
    &_sendBtn
      display: flex
      align-items: center
      justify-content: center
      width: 45px
      height: 45px
      border: none
      padding: 10px
      border-radius: 50%
      background: linear-gradient(90deg, #166AE3 0%, #2197E8 100%)
      @media only screen and (max-width : 768px)
        width: 100%
        border-radius: $border-radius-primary
        margin: 1rem 10px
        flex-direction: row-reverse
      &_label
        display: none
        color: $white
        margin-right: 1rem
        font-size: $base_font_size
        @media only screen and (max-width : 768px)
          display: flex
          justify-content: center


  &_sendIcon
    width: 32px
    height: 32px
    cursor: pointer
    fill: $white
    path
      fill: $white

  &_disableContainer
    display: flex
    justify-content: center
    align-items: center
    padding: 20px
    &_text
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 22px
      color: $grey-light

  &_toolsContainer
    display: flex
    align-items: center
    justify-content: center
    border-top: none
    padding: 1rem 1rem 0 1rem
    &_Profile
      border-bottom: 1px solid $sky-blue-light
      flex: 1 1 auto
      display: flex
      align-items: center
      justify-content: space-between
      padding-bottom: 0.5rem
      &_About
        display: flex
        align-items: center
      &_Avatar
        width: 50px
        height: 50px
        border: 1px solid transparent
        border-radius: 100%
        background-size: cover
        background-position: center center
        margin-right: 1rem
      &_Name
        color: $royal-blue
        font-weight: 600

  &_timer
    font-size: 16px
    line-height: 20px
    color: $grey-medium
    font-weight: normal
    font-family: 'Helvetica Neue', serif
    font-style: normal

  &_title
     font-family: 'Helvetica Neue', serif
     font-style: normal
     font-weight: normal
     font-size: 16px
     line-height: 20px
     color: $spring-green
     text-align: center


.appointmentFiles
  margin: 0 auto
  padding: 20px 20px 40px

  &_list
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(calc(30% - 12px), calc(30% - 12px)))
    grid-gap: 20px

    &_item
      display: flex
      height: 120px
      cursor: pointer
      background-color: $grey-bg
      background-size: cover
      background-position: center center
      background-repeat: no-repeat
      justify-content: center
      align-items: center

.menuOverlay
  z-index: 2
  background-color: white
  height: 100%
  width: 100%

.menuContainer
  width: 100%
  height: 100%

@media (min-width: 320px) and (max-width: 768px)
  .menuOverlay
    width: 100%

