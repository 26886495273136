@import '../../../../assets/sass/vars'
.root
  width: 100%
  display: flex
  align-items: flex-start
  flex-direction: column
  &_title
    margin-bottom: 2rem
    @media (max-width: 480px)
      text-align: left
  &_value
    font-weight: 500
  &_text
    color: $royal-blue
    margin-bottom: 1rem
    li
      margin-bottom: .5rem
    &_mute
      color: $silver
  &_actions
    display: flex
    flex-direction: row
    gap: 1rem
    align-items: flex-start
    @media (max-width: 480px)
      width: 100%
      justify-content: space-between
      flex-direction: column
      align-items: stretch
      gap: 0
    button
      padding-left: 2rem
      padding-right: 2rem
