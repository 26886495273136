@import '../../../assets/sass/vars'
.root
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  align-items: stretch
  padding: 2rem 10rem
  justify-content: center
  flex: 1 1 auto
  @media only screen and (max-width : 480px)
    padding: 2rem 1rem
  &_date
    display: flex
    align-items: center
    justify-items: center
    gap: 1rem
    margin-bottom: 2rem
    position: relative
    &_popover
      position: absolute
      padding: 1rem
      z-index: 10000
      top: 90px
      background-color: $white
      border-radius: $border-radius-primary
      box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)
      &_time
        right: 0
        &_fixed
          right: inherit
          position: fixed
        @media only screen and (max-width : 480px)
          top: 180px
    @media only screen and (max-width : 480px)
      width: 100%
      flex-direction: column
    &_item
      width: 100%
      position: relative
      cursor: pointer !important
    &_input
      color: $royal-blue
      font-weight: 500
      font-size: 18px
      cursor: pointer !important
    &_icon
      color: $royal-blue
      position: absolute
      right: 10px
      top: 35px
      cursor: pointer !important
  &_title
    display: flex
    justify-content: center
    font-weight: 600
    font-size: 22px
    color: $royal-blue
    margin-bottom: 2rem
  &_values
    display: flex
    align-items: center
    justify-items: center
    gap: 1rem
    @media only screen and (max-width : 480px)
      width: 100%
      flex-direction: column
    &_error
      color: $red
      display: flex
      justify-content: center
      align-items: center
      margin-top: 1rem
      white-space: pre-wrap
  &_actions
    display: flex
    flex-direction: column
    align-items: stretch
    justify-items: center
    gap: 1rem
    padding: 2rem
  &_value
    width: 100%
    display: flex
    flex-direction: column
    align-items: stretch
    &_label
      margin-top: 1rem
      display: flex
      justify-content: center
      font-size: 18px
      color: $sky-purple
    &_input
      font-weight: 600
      color: $royal-blue
      height: 80px
      line-height: 80px
      font-size: 30px
      text-align: center
      display: flex
      justify-content: center
      align-items: center
      overflow: visible
      position: relative
      &_desc
        margin-top: 2rem
        height: 200px !important
      &::placeholder
        line-height: 80px
        font-size: 24px

