@import '../../../assets/sass/vars'
.AgentPasswordDialog
  max-width: 439px
  margin: 0 auto
  &_title
    margin-bottom: 20px
    font-size: 24px
    font-weight: 500
    text-align: center
    &_sub
      color: $slate-grey
  &_form
    padding: 0 20px
    @media only screen and (max-width : 480px)
      padding: 0
