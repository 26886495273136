@import '../../../assets/sass/vars'


.Docs
  width: 100%
  padding: 10px
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  &_Item
    margin: 5px
    width: 45%
    border: 1px solid $grey-light
    border-radius: 8px
    padding: 5px
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    &_Actions
      margin-top: 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      & > button
        width: 100% !important
    &_Data
      width: 100%
      display: flex
      justify-content: flex-start
      align-items: center
      &_Icon
        width: 45px !important
        height: 45px !important
        margin-right: 10px
        color: $sky-blue
      &_Value
        color: $slate-grey
        font-weight: bold
