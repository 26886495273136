@import '../../assets/sass/vars'

.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  background-color: transparent !important
  box-shadow: none !important
.root
  margin-bottom: 2rem
  &_tabs
    margin-bottom: .1rem
    width: 100%
    justify-content: space-around
    display: flex
    align-items: center
    &_tab
      width: 100%
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      padding: 1rem 2rem
      color: $grey-medium
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      border-bottom: 3px solid $sky-blue-light
      &:hover, &_active
        color: $sky-blue
        border-bottom: 3px solid $sky-blue
  &_title
    display: flex
    justify-content: space-between
    align-content: center
    align-items: center
    background: $sky-blue-light-second
    border: 1px solid $sky-blue-light
    border-radius: $border-radius-primary
    padding: 1rem
    & > h6
      flex: 1 1 auto
  &_profile
    flex: 1 1 auto
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1rem
    &_about
      flex: 1 1 auto
      width: 100%
      display: flex
      align-items: center
    &_avatar
      width: 50px
      height: 50px
      border: 1px solid transparent
      border-radius: 100%
      background-size: cover
      background-position: center center
      margin-right: 1rem
    &_name
      display: flex
      flex-direction: column
    &_label
      color: $grey-medium
      font-weight: normal
    &_value
      color: $royal-blue
      font-weight: 600
