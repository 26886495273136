@import '../../../../assets/sass/vars'
.root
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: flex-start
  &_toolbar
    margin: 1rem 0
    display: flex
    gap: 1rem
    align-items: center
  &_items
    display: flex
    flex-direction: column
    align-items: stretch
    align-content: center
  &_item
    cursor: pointer
    border: 1px solid $grey-light
    border-radius: $border-radius-primary
    background-color: $white
    margin-bottom: 1rem
    padding: 1rem
    display: flex
    flex-direction: column
    min-height: 5rem
    align-items: stretch
    &_wrapper
      display: flex
      align-items: center
    &_data
      flex: 1 1 auto
      width: 100%
    &_action
      display: flex
      justify-content: center
      align-items: flex-end
    &_name
      color: $slate-grey
      font-weight: 500
      margin-bottom: 1rem
    &_text
      color: $slate-grey
    &_tech
      color: $red
.ServicePackagesAdminGroups
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  flex: 1 1 auto
  height: 100%
  width: 100%

  &_Toolbar
    display: flex
    justify-content: center
    align-items: center
    padding: 10px 0

    &_Search
      position: relative
      padding: 10px
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%

      input
        background-color: $grey-light
        width: 100%
        padding: 12px 40px

      &_Icon
        position: absolute
        left: 10px
        top: 10px
        width: 22px !important
        height: 22px !important
        margin: 10px

        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important

  &_Action
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 0

  &_Content
    display: flex
    flex: 1 1 auto
    height: 100%
    flex-direction: column
    align-items: center
    justify-content: center

  &_Section
    width: 100%
    border-bottom: 1px solid $grey-light

    &:last-child
      border-bottom: none

  &_Items
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    height: 100%
    width: 100%
    flex: 1 1 auto
    @media (max-width: 480px)
      padding: 10px
    padding: 10px

    &_Item
      border: 1px solid $grey-light
      width: 100%
      display: flex
      border-radius: 8px
      justify-content: space-between
      align-items: stretch
      padding: 5px 10px
      margin-bottom: 10px
      cursor: pointer

      &_Data
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start

        &_Name
          color: $slate-grey
          font-weight: 500
          margin-bottom: 5px

        &_Text
          color: $slate-grey
          &_TECH
            color: $red

        &_Status
          &_NEW
            color: $sky-blue

          &_ACTIVE
            color: $spring-green

          &_ARCHIVE
            color: $grey-light

      &_Actions
        display: flex
        justify-content: center
        align-items: center



