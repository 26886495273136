@import '../../../../assets/sass/vars'
.SchedulerDoctorPageAdmin
  &_Content
    padding: 0
    &_Grid
      border-color: lightgray  !important
  &_Sidebar
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    padding: 0
    width: 100%
    height: 100%
    border-right: 1px solid
  &_Toolbar
    &_Date
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: stretch
      text-transform: capitalize
      &_Nav
        background-color: transparent !important
        border: none
        cursor: pointer
        &_Icon
          color: $grey-light
          width: 9px
          height: 16px
          +mt(.2s)
          fill: $grey-light
          path
            fill: $grey-light
        &_Label
          font-size: 16px
          color: $slate-grey
          font-weight: 500
          text-transform: capitalize



