@import '../../assets/sass/vars'
.EventsDoctor
  &_Wrapper
    background: $white
    border: 1px solid $sky-blue-light
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    border-radius: 20px
    overflow: hidden
  &_InfiniteScroll
    width: 100%
  &_ProfileImg
    border: 1px solid $grey-light
    margin-right: 10px
  &_Row
    cursor: pointer
    border-bottom: 1px solid $sky-blue-light
    padding: 10px
    @media only screen and (max-width : 768px)
      display: flex !important
      flex-direction: column !important
    &:last-child
      border-bottom: 1px solid transparent
    &_Header
      background: $sky-blue-light-second
      border-bottom: 1px solid $sky-blue-light
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
    &_Cell
      padding: 0 5px
      color: $royal-blue
      width: 33.3333%
      font-weight: 500
      display: flex
      justify-content: flex-start
      align-items: center
      @media only screen and (max-width : 768px)
        width: 100%
        margin-bottom: 1rem
      &_Status
        &_IN_PROGRESS
          color: $sky-blue
        &_CANCELED
          color: $yellow-accent
        &_DONE
          color: $light-grey
        &_AWAITS
          color: $spring-green
        &_FINISHED
          color: $steel-blue
        &_FAILED
          color: $red
        &_WAITING_FILLING
          color: $yellow-accent
      &_Header
        @media only screen and (max-width : 768px)
          display: none
        &:first-child
          @media only screen and (max-width : 768px)
            display: block

        color: $black-blue
  &_Status
    display: block
    align-items: center
    width: 12px
    height: 12px
    border-radius: 50%
    &_IN_PROGRESS
      background-color: $sky-blue
    &_CANCELED
      background-color: $yellow-accent
    &_DONE
      background-color: $light-grey
    &_AWAITS
      background-color: $spring-green
    &_FINISHED
      background-color: $steel-blue
    &_FAILED
      background-color: $red
    &_WAITING_FILLING
      background-color: $yellow-accent
  &_Popover
    margin-top: 20px !important
    padding: 0px
    height: auto !important
    border-radius: 10px !important
    &_Date
      justify-content: center
      align-items: center
      display: flex
      flex-direction: column
      padding: 0
      &_Reset
        padding: 5px 10px
        border-top: 1px solid $grey-light
    &_Status
      padding: 0 !important
  &_Toolbar
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 0
    @media only screen and (max-width : 480px)
      flex-direction: column
    &_Search
      display: flex
      flex: 1 1 auto
      padding: 0 20px
      @media only screen and (max-width : 480px)
        padding: 0 5px
        width: 100%
    &_Buttons
      display: flex
      justify-content: flex-start
      align-items: stretch
      padding: 0 5px
      @media only screen and (max-width : 480px)
        justify-content: space-between
        padding: 5px
        width: 100%
    &_Button
      position: relative
      display: flex
      justify-content: center
      align-items: center
      @media only screen and (max-width : 480px)
        width: 50%
      button
        min-width: 100px
        height: 100%
      &:first-child
        margin-right: 5px
      &_Label
        position: absolute
        top: -3px
        right: -3px
    &_StatusList
      padding: 0
      margin: 0
      list-style: none
      &_Item
        width: 100%
        cursor: pointer
        display: flex
        border-radius: 5px
        justify-content: space-between
        align-items: center
        padding: 5px
        &_Icon
          min-width: 32px
          margin-left: 10px
        &:hover
          background-color: lighten($sky-blue, 50%)
      li
        padding: 5px 10px
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        &:last-child
          cursor: default
          justify-content: center
          border-top: 1px solid $grey-light
