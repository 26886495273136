@import '../../assets/sass/vars'

.root
  &_item_userProfile
    display: flex
    position: relative
    align-items: center
    padding: 6px 12px
    cursor: pointer
    text-decoration: none
    border-radius: 10px
    color: $sky-blue
    background-color: transparent
    border-left: none
    border-top: none
    border-bottom: none
    border-right: 4px transparent solid
    font-size: $base_font_size
    font-weight: 400
    line-height: 20px
    font-family: $base-font
    width: 100%
    & > div
      text-align: left
    &:hover
      background-color: $sky-blue-light
    &_icon
      width: 18px !important
      height: 18px !important
      margin-right: 1rem
      fill: $sky-blue
      path
        fill: $sky-blue
    input[type=file]
      opacity: 0
      width: 100%
      position: absolute
      height: 100%
      pointer-events: auto
      cursor: pointer
  &_item
    display: flex
    position: relative
    justify-content: flex-start
    align-items: center
    padding: 6px 13px
    font-size: 18px
    line-height: 22px
    cursor: pointer
    color: $sky-purple
    font-weight: 500
    border-right: 4px transparent solid
    background-color: transparent
    border-left: none
    border-top: none
    border-bottom: none
    font-family: $base-font
    input[type=file]
      opacity: 0
      width: 100%
      position: absolute
      height: 100%
      pointer-events: auto
      cursor: pointer
    &_active
      border-right-color: $sky-blue
    &:hover, &_active
      color: $sky-blue
      .root_item_icon
        fill: $sky-blue
        path
          fill: $sky-blue
    &_icon
      width: 18px !important
      height: 18px !important
      margin-right: 1rem
      fill: $sky-purple
      path
        fill: $sky-purple
