@import '../../assets/sass/vars'


.appointmentInfo
  margin: 0 auto
  text-align: center
  &_date
    margin-top: 10px
    font-size: 16px
    color: $sky-blue
    line-height: 20px
  &_msg
    margin-top: 20px
    color: #000
    font-size: 16px
    line-height: 22px
  &_btn
    margin-top: 40px
    max-width: 360px
    &_subtitle
      margin-top: 10px
      font-size: 16px
      line-height: 22px
      color: $grey-medium

.doctorDetailsInfo
  text-align: center
  &_avatar
    display: inline-block
    width: 100px
    height: 100px
    margin-bottom: 15px
    border: 1px solid $sky-blue
    border-radius: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
  &_name
    font-size: 20px
    line-height: 24px
    font-weight: 500
  &_position
    margin-top: 4px
    color: $grey-medium
    font-size: 16px
    line-height: 20px

.appointmentFeedback
  &_date
    margin: -30px -20px 20px -20px
    padding: 10px
    color: $spring-green
    font-size: 16px
    line-height: 20px
    text-align: center
    border-bottom: 1px solid $grey-light
  &_conclusion
    display: flex
    flex-direction: column
    padding-top: 16px
    margin: 10px -20px 0px
    align-items: flex-start
    border-bottom: 1px solid $grey-light
    border-top: 1px solid $grey-light
    &_title
      margin: 4px 16px
      font-size: 16px
      line-height: 20px
      font-weight: 500
      color: $black
    &_recommendations
      margin: 4px 16px
      text-align: start
      color: $grey-medium
      font-size: 16px
      line-height: 20px
    &_footer
      align-self: center
      padding: 8px
      color: $grey-medium
      font-size: 16px
      line-height: 20px
  &_patientFeedback
    width: 550px
    margin: 16px auto
    text-align: center
    &_rate
      svg
        transform: scale(1.5) !important
        fill: $silver !important
        cursor: pointer
        path
          fill: $silver !important
        &.rateActive
          fill: $gold !important
          path
            fill: $gold !important

        &:not(:last-child)
          margin-right: 26px !important
    &_title
      margin-bottom: 20px
      font-size: 16px
      font-weight: 500
      color: #000
    &_subtitle
      color: $grey-medium
      font-size: 16px
      line-height: 20px
    &_form
      max-width: 450px
      margin: 0 auto
      margin-top: 40px
      justify-content: center

@media (min-width: 320px) and (max-width: 768px)
  .appointmentFeedback
    &_patientFeedback
      width: 100%
      &_form
        width: 100%
  .mt-4
    font-size: 12px
