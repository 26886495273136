@import '../../assets/sass/vars'
.root
  text-align: center
  &_star
    display: inline-block
    fill: $grey-medium
    transform: scale(0.8)
    path
      fill: $grey-medium
    &:not(:last-child)
      margin-right: 6px
  &_active
    fill: $flush-orange
    path
      fill: $flush-orange
