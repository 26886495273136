@import '../../assets/sass/vars'
@import '../../assets/sass/mixin'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 400px
  width: 700px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
.DeleteDialog
  width: 100%
  min-height: 200px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  @include modal-dialog
  &_Header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    position: relative
    @include modal-header
    h6
      margin: 0
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 0 20px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: space-between
  &_CloseIcon
    color: $slate-grey
    font-size: 16px
    @include modal-close

