@import "vars"
@mixin modal-dialog
  background-color: $white !important
  border: 1px solid $sky-blue-light
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  overflow: hidden
  padding: 0 !important
  margin: 1rem !important
  border-radius: 15px !important
@mixin modal-header
  background: $sky-blue-light-second
  border-bottom: 1px solid $sky-blue-light
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  padding: 1rem
  h6
    word-break: break-word
    margin: 0
    text-align: center
    flex: 1 1 auto
@mixin modal-close
  cursor: pointer
