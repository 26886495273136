@import '../../../assets/sass/vars'


.registerScreen
  max-width: 420px
  margin: 0 auto
  &_title
    margin-bottom: 20px
    font-size: 24px
    font-weight: 500
    text-align: center
  &_descr
    margin-bottom: 40px
    font-size: 16px
    line-height: 20px
    font-weight: 400
    text-align: center
  &_form
    max-width: 360px
    margin: 0 auto

.sexSelectMenuDropdown, .sexSelectMenuDropdownToggle, .sexSelectMenuFormGroup
  width: 100%

.sexSelectMenuDropdownContent
  padding: 6px 12px
  width: 100%

.sexSelectMenuList
  display: flex
  &_item
    position: relative
    width: 50%
    padding: 6.5px 10px
    text-align: center
    cursor: pointer
    border-radius: 10px
    +mt(.2s)
    &:not(:last-child)
      margin-right: 19px
      &::after
        content: ''
        position: absolute
        top: 0
        right: -10px
        width: 1px
        height: 32px
        background-color: $grey-light
    &:hover, &_active
      background-color: lighten($sky-blue, 55%)
      color: $sky-blue
