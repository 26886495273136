@import '../../assets/sass/vars'
.Backdrop
  background-color: transparent !important
.Dialog
  height: auto
  max-width: 100% !important
  min-width: 600px
  background-color: transparent !important
  box-shadow: none !important
  overflow: hidden
  &_View
    width: 100%
    height: auto
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: stretch
    &_Header
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      position: relative
      h6
        margin: 0
    &_Content
      flex: 1 1 auto
      height: auto
      min-height: 0
      padding: 10px
      display: flex
      align-items: stretch
      flex-direction: column
      justify-content: space-between
      iframe
        border: #1473ff
        width: 100%
        min-height: 600px
    &_CloseIcon
      color: $slate-grey
      font-size: 16px
.PatientsDoctor
  &_InfiniteScroll
    width: 100%
  &_Conclusion
    color: $slate-grey
    display: flex
    justify-content: flex-start
    align-items: center
    &_Icon
      color: $sky-blue
      width: 22px !important
      height: 22px !important
      margin-right: 10px
  &_Patient
    padding: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    &_Header
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      color: $royal-blue
      font-weight: 600
    &_Appointments
      width: 100%
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
      &_Row
        display: flex
        align-items: center
        justify-content: space-between
        flex-direction: row
        cursor: pointer
        border-bottom: 1px solid $sky-blue-light
        padding: 1rem
        @media only screen and (max-width : 480px)
          flex-direction: column
          align-items: stretch
        &:last-child
          border-bottom: 1px solid transparent
        &_Header
          cursor: default
          border-bottom: 1px solid transparent
          @media only screen and (max-width : 480px)
            display: none !important
        &_Cell
          padding: 0 2px
          color: $royal-blue
          width: 33%
          font-weight: 600
          @media only screen and (max-width : 480px)
            padding: 5px
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            &:first-child
              align-items: center
              flex-direction: column
          &_Status
            font-weight: 500 !important
            &_IN_PROGRESS
              color: $sky-blue
            &_CANCELED
              color: $yellow-accent
            &_DONE
              color: $light-grey
            &_AWAITS
              color: $spring-green
            &_FINISHED
              color: $steel-blue
            &_FAILED
              color: $red
            &_WAITING_FILLING
              color: $yellow-accent
          &_Header
            color: $light-grey
  &_Profile
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    @media only screen and (max-width : 480px)
      flex-direction: column
      width: 100%
      align-items: center
      margin-bottom: 1rem
    &_Name
      color: $royal-blue
      font-weight: 600
      display: flex
      align-items: center
      word-break: break-all
      @media only screen and (max-width : 480px)
        margin-top: 1rem
    &_Age
      color: $royal-blue
      font-weight: 600
  &_ProfileImg
    border: 1px solid $grey-light
    margin-right: 10px
  &_Items
    background: $white
    border: 1px solid $sky-blue-light
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    border-radius: 20px
    overflow: hidden
  &_Row
    cursor: pointer
    border-bottom: 1px solid $sky-blue-light
    padding: 1rem
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    &_Collapsed
      background: $sky-blue-light-second
      border: 1px solid $sky-blue-light
      box-shadow: 10px 20px 50px rgba(229, 233, 246, 0.5)
      border-radius: 20px
      margin: 10px
      padding: 0
      width: calc(100% - 20px)
      & > div:first-child
        padding: 1rem
    &_Header
      background: $sky-blue-light-second
      border-bottom: 1px solid $sky-blue-light
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
      @media only screen and (max-width : 480px)
        display: none
        &:first-child
          display: flex
          align-items: center
          justify-content: center
    &_Main
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      @media only screen and (max-width : 480px)
        flex-direction: column
        align-items: stretch
    &_Info
      width: 100%
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
    &:last-child
      border-bottom: 1px solid transparent
    &_Cell
      padding: 0 1rem
      display: flex
      justify-content: flex-start
      align-items: center
      &:first-child
        width: 25%
      &:last-child
        display: flex
        width: 35%
      &_Age
        width: 15%
      &_Journal
        width: 25%
      @media only screen and (max-width : 480px)
        width: 100% !important
        justify-content: center
      &:first-child
        justify-content: flex-start
        @media only screen and (max-width : 480px)
          justify-items: center
          flex-direction: column
          align-items: center
      &_Header
        font-weight: 500
        color: $black-blue
        padding: 0 1rem
        justify-content: flex-start
        @media only screen and (max-width : 768px)
          display: none
        &:first-child
          @media only screen and (max-width : 768px)
            display: flex
  &_Button
    color: $sky-blue
    font-weight: 600
    border: none
    border-bottom: 1px dotted $sky-blue
    box-shadow: none
    border-radius: 0
    padding-left: 0
    padding-right: 0
    margin: 0
    background: transparent
    &:hover
      box-shadow: none
    &_Icon
      color: $sky-blue
      width: 32px
      height: 32px
      margin-left: 10px
  &_Toolbar
    display: flex
    justify-content: stretch
    align-items: center
    &_Search
      margin: 0 20px
      position: relative
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      background-color: $white
      border: 3px solid $sky-blue !important
      border-radius: 15px
      input
        background-color: $white
        border: none
        border-radius: 15px
        width: 100%
      &_Icon
        width: 23px !important
        height: 23px !important
        margin: 10px
        &_Clear
          cursor: pointer
          left: auto !important
          right: 10px !important


