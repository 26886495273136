@import '../../../assets/sass/vars'


.customCheckbox
  display: block
  position: relative
  padding-left: 30px
  text-align: left
  cursor: pointer
  user-select: none
  font-size: 16px
  color: $grey-medium
  line-height: 19px
  &:not(:last-child)
    margin-bottom: 16px
  &_is_invalid
    color: $red
    .checkmark
      color: $red
  input
    display: none

.checkboxDisabled
  opacity: .6
  cursor: default

.checkboxInline
  display: inline-block
  margin-bottom: 0
  &:not(:last-child)
    margin-right: 24px
.checkmark
  color: $sky-blue
input:checked ~ .checkmark_checked
  display: inline-block !important
input:checked ~ .checkmark_unchecked
  display: none !important
input:not(:checked) ~ .checkmark_checked
  display: none !important
input:not(:checked) ~ .checkmark_unchecked
  display: inline-block !important
.checkmark
  display: none
  position: absolute
  top: -1px
  left: 0
  width: 20px
  height: 20px

