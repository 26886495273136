@import '../../assets/sass/vars'
.AgentCertsGenerateDialog
  &_Content
    flex: 1 1 auto
    height: auto
    min-height: 0
    padding: 24px
    display: flex
    align-items: stretch
    flex-direction: column
    justify-content: flex-start
    &_Wrapper
      width: 100%
      height: 100%
      overflow: auto
      display: flex
      flex-direction: column
      flex: 1 1 auto
    &_Item
      padding: 10px
      border-bottom: 1px solid $light-grey
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      &_Container
        align-items: stretch !important
        padding: 0 0 20px 0
      &:last-child
        border-bottom: none
