@import '../../assets/sass/vars'


.button
  background: transparent
  border: none
  box-shadow: none
  padding: 0

  &:disabled
    color: #828282

.group
  display: flex
  align-items: center
  justify-content: space-between
  flex-wrap: wrap

.PinIcon
  width: 22px
  margin-right: 20px
  fill: $white
  path
    fill: $white


@media only screen and (max-width : 768px)
  .group
    .button
      margin-top: 15px
      flex: 0 0 100%
