@import '../../assets/sass/vars'


.doctorsFilterCardHeader
  padding: 0

.doctorsFilterCardMain
  width: 100%
  margin-top: 2rem
  min-height: 0
  background: #EFF8FF
  border: 1px solid #A1D7FF
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 15px

.doctorsFilterSearchForm
  margin-bottom: 20px
  &_input
    padding-top: 10px
    padding-bottom: 10px

.contentCard_menu
  &_item
    width: 50%

#filterTypesIconItem
  button
    padding: 4px
  svg
    height: 30px
    width: 30px

.doctorsFilterTypesList
  display: flex
  align-items: center
  width: 100%
  overflow-y: auto
  padding-bottom: 10px
  &_item
    cursor: pointer
    &:first-child
      margin-right: 20px !important
    &:not(:last-child)
      margin-right: 10px
    &_active
      pointer-events: none
      button
        background-color: $grey-bg

.doctorsList
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 12px), calc(50% - 12px)))
  grid-gap: 24px
  &_infinitescroll
    overflow: hidden
  &_card
    &_duty
      .doctorsList_card_wrapper
        flex-direction: column
        align-items: center
      .doctorsList_card_about
        align-items: center
      .doctorsList_card_avatar
        svg
          circle
            stroke: $sky-blue !important
          fill: $sky-blue !important
          path
            fill: $sky-blue !important
    position: relative
    height: 100%
    padding: 20px 25px
    background-color: #fff
    border: 1px solid $sky-blue-light
    text-align: center
    display: flex
    flex-direction: column
    align-items: stretch
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4)
    border-radius: 20px
    &_wrapper
      display: flex
      align-items: stretch
      justify-content: space-between
      flex: 1 1 auto
      @media only screen and (max-width : 768px)
        flex-direction: column
    &_about
      padding: 0 15px
      flex: 1 1 auto
      display: flex
      flex-direction: column
      align-items: flex-start
      @media only screen and (max-width : 768px)
          align-items: center
    &_favorite
      display: flex
      justify-content: flex-end
      &_active
        svg
          fill: $red !important
          path
            fill: $red !important
      svg
        width: 22px
        cursor: pointer
        fill: $grey-light
        +mt(.1s)
        path
          fill: $grey-light
          +mt(.1s)
        &:hover
          fill: $red
          path
            fill: $red
    &_avatar
      display: flex
      align-items: flex-start
      justify-items: center
      margin-bottom: 20px
      & > div
        width: 80px
        height: 80px
        border-radius: 100%
        background-size: cover
        background-position: center center
      @media only screen and (max-width : 768px)
        margin: 0 auto 1rem auto
    &_name
      color: $royal-blue
      font-size: 20px
      line-height: 24px
      font-weight: 600
      text-align: left
      @media only screen and (max-width : 768px)
        text-align: center
    &_descr
      margin-top: 4px
      margin-bottom: 8px
      font-size: 16px
      line-height: 20px
      color: $grey-medium
      text-align: left
    &_reviews
      display: inline-flex
      align-items: flex-end
      margin-top: 11px
      font-size: 16px
      line-height: 20px
      color: $grey-medium
      flex: 1 1 auto
      svg
        width: 20px
        margin-right: 8px
        stroke: $sky-blue
        path
          stroke: $sky-blue
    &_price
      margin-top: 1rem
      margin-bottom: 1rem
      color: $sky-blue
      font-size: 20px
      line-height: 24px
      font-weight: 600
    &_btn
      margin-top: 20px
      height: 44px
      font-size: 16px
      font-weight: 500
      padding-top: 1px
      padding-bottom: 1px
      display: flex
      flex-wrap: nowrap
      justify-content: center
      @media only screen and (max-width : 768px)
        min-height: 44px
        height: auto
        & > div:first-child
          margin-right: 0
          display: flex
          align-items: center
      & > div:last-child
        text-align: center
        flex: 1 1 auto
        overflow: hidden
        text-overflow: ellipsis
        display: flex
        white-space: break-spaces
        justify-content: center
        @media only screen and (max-width : 768px)
          padding: 0 0.1rem
      svg
        height: 40px
        fill: #fff
        path
          fill: #fff


@media only screen and (max-width : 790px)
  .contentCard_menu
    flex-wrap: wrap
    &_item
      width: 100%

@media only screen and (max-width : 768px)
  .doctorsList
    grid-template-columns: repeat(auto-fit, minmax(100%, 100%))
    grid-gap: 16px
    margin-top: 16px

@media only screen and (max-width : 480px)
  .doctorsList
    &_card
      padding: 15px
