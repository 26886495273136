@import '../../assets/sass/vars'
.Dialog
  width: 100%
  height: 100%
  position: relative !important
  background: none !important
  padding: 0 !important
  margin: 0 !important
  transition: none !important
  box-shadow: none !important
  max-width: 100% !important
  display: flex !important
  align-items: center !important
  justify-content: center !important
.CallDialog
  z-index: 999
  display: block
  position: relative !important
  @media only screen and (max-width : 480px)
    width: 100% !important
    max-width: 100% !important
    display: flex !important
    align-items: center !important
    justify-content: center !important
  &_Full
    position: fixed !important
    width: 100% !important
    height: 100% !important
    left: 0 !important
    transform: none !important
    .CallDialog_wrapper
      width: 100% !important
      height: 100% !important
  &_wrapper
    width: 400px
    height: 600px
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    @media only screen and (max-width : 480px)
      width: 90%
  &_container
    width: 100%
    height: 100%
    background-color: #000
    display: flex
    flex-direction: column
    justify-content: space-between
    align-content: center
    align-items: center
    position: relative
  &_videoContainer
    position: fixed
    width: 100%
    height: 100%
    &_hidden
      display: none
    &_local
      position: absolute
      background-color: transparent
      top: 5px
      right: 5px
      min-width: 120px
      min-height: 200px
      max-height: 50%
      max-width: 30%
      video
        width: 100% !important
        height: 100% !important
      z-index: 1001
    &_remote
      width: 100%
      height: 100%
      z-index: 1000
      background-color: transparent
      video
        width: 100% !important
        height: 100% !important
  &_remoteContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 16px
    flex: 1 1 auto
    height: 100%
    &_hidden
      visibility: hidden
    &_avatarContainer
      display: flex
      align-items: center
      justify-content: center
      &_avatar
        width: 120px !important
        height: 120px !important
        margin-bottom: 25px
    &_name
      font-size: 20px
      line-height: 24px
      font-weight: 500
      color: $white
    &_status
      display: flex
      justify-content: center
      color: $white
  &_tools
    display: flex
    padding: 16px
    flex-direction: column
    align-items: flex-start
    justify-content: stretch
    &_line
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 10px
    &_button
      border: none !important
      width: 50px
      padding: 0 !important
      line-height: 50px !important
      height: 50px !important
      display: flex !important
      justify-content: center !important
      align-items: center !important
      background-color: $light-grey !important
      min-width: auto !important
      border-radius: 50% !important
      &_active
        background-color: $grey-light !important
      &_success
        background-color: $spring-green !important
      &_close
        background-color: $red !important
    &_icon
      display: inline-block
      width: 40px !important
      height: 40px !important
      color: $white
  &_IconButton
    padding: 6px !important
    position: relative
  &_Status
    width: 10px
    height: 10px
    background-color: $red
    top: 0
    right: 0
    position: absolute
    border-radius: 50%
  &_Icon
    cursor: pointer
    color: $grey-light
